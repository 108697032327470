import { React } from 'react';
import { useState } from 'react';
import $ from './DropDown.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

export default function DropDown(p) {

    const {
        title,
        type = 'text',
        color = 'var(--texto1)',
        background = 'transparent',
        border = 'solid',
        get,
        set,
        autocomplete = "on",
        disabled = false,
        placeholder = '',
        onBlur,
        list
    } = p;

    const [focused, setFocused] = useState(false)
    const [expand, setExpand] = useState(false)

    const clickHandler = () => {
        setFocused(!focused);
        if (!focused) {
            setExpand(true)
        }
    }

    const executeBlur = () => {
        setFocused(false)
        setExpand(false)
        if (onBlur)
            onBlur()
    }

    return (
        <div className={$.container} tabIndex="-1" onClick={() => clickHandler()} onBlur={() => executeBlur()}>
            <div className={$.wrapper} >
                <span className={$.label}
                    style={{
                        top: focused || get ? '-8px' : '18px',
                        color: focused && !disabled ? 'var(--texto1)' : 'var(--texto2)',
                        background: focused || get ? 'var(--destaque)' : 'transparent'
                    }}>
                    {title}
                </span>
                <div
                    value={get}
                    className={$.input}
                    type={type}
                    onClick={() => !disabled && setExpand(!expand)}
                    id={title}
                    autoComplete={autocomplete}
                    placeholder={placeholder}
                    maxLength={150}
                    style={{ color: color, backgroundColor: background, border: ('1px ' + border + ' var(--texto2)') }}>
                    <div className={$.value}>
                        <div>
                            {list?.filter(item => item.key === get)[0]?.value}
                        </div>
                        <FontAwesomeIcon
                            icon={faChevronDown}
                            style={{
                                transform: `rotate(${expand ? '180deg' : '0deg'})`

                            }}
                        />
                    </div>
                    {
                        expand &&
                        <div className={$.list}>
                            <div className={$.item} onClick={() => set()}>Nenhum</div>
                            {list && list.map((item) =>
                                <div className={$.item} key={item.key} onClick={() => set(item.key)}>{item.value}</div>
                            )}
                        </div>
                    }
                </div>
            </div>
        </div >
    )
}
