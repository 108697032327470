import React from 'react';
import $ from './MenuGroup.module.css'

export default function MenuGroup(p) {

    const { title, children } = p;

    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <div className={$.title}>{title}</div>
                <div className={$.content}>{children}</div>
            </div>
        </div>
    )

}
