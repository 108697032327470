import $ from './Professional.module.css'
import { React, useEffect, useState, useContext } from 'react';
import { UserContext } from '../../Context/UserContext';
import TextField from '../../Components/TextField/TextField';
import Button from '../../Components/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { areas } from '../../Utils/Professional';
import CheckBox from '../../Components/CheckBox/CheckBox';
import { removeUserArea, saveUserArea } from '../../Services/Database/User';
import { getImageUrl, saveImage } from '../../Services/User';
import { useRef } from 'react';
import Loading from '../../Components/Loading/Loading';
import { v4 as uuidv4 } from 'uuid';
import { AlertContext } from '../../Context/AlertContext';

const MAX_IMAGE_SIZE = 100 * 1024;
const validExtensions = ['jpeg', 'jpg', 'png'];

export default function Professional(params) {

    const { handleChanges } = params;

    const { user, setUser } = useContext(UserContext);
    const [professionalCode, setProfessionalCode] = useState('');
    const [cpf, setCPF] = useState('');
    const [about, setAbout] = useState('');
    const [uploading, setUploading] = useState(false);
    const { alerts, setAlerts } = useContext(AlertContext);
    const fileRef = useRef(null);

    const handleButton = (show = true) => {
        var updatedUser = user;
        updatedUser.professionalCode = professionalCode ? professionalCode : '';
        updatedUser.cpf = cpf ? cpf : '';
        updatedUser.about = about ? about : '';
        handleChanges(updatedUser, show);
    }

    const handleClickUpload = () => {
        fileRef.current.click();
    }

    const showAlert = (type, message) => {
        const id = uuidv4();
        const newAlert = { id, message, type };
        setAlerts([...alerts, newAlert]);
    };


    const handleUpload = (e) => {
        const file = e.files[0];
        if (file) {
            const extension = file.name.split('.').pop().toLowerCase();
            if (!validExtensions.includes(extension)) {
                showAlert('error', 'Extensão do arquivo invalida. Utilize somente imagens JPEG, JPG, ou PNG.')
                setUploading(false);
                return;
            }
            setUploading(true)
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const img = new Image();
                img.src = reader.result;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const scaleFactor = Math.min(1, MAX_IMAGE_SIZE / file.size);
                    canvas.width = img.width * scaleFactor;
                    canvas.height = img.height * scaleFactor;
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                    const base64 = canvas.toDataURL(file.type);
                    saveImage('cover_' + user.id, base64).then((response) => {
                        const updatedUser = { ...user, cover: getImageUrl(response.url) };
                        handleChanges(updatedUser, false);
                        setUploading(false);
                    });
                };
            };
        }
    };

    const handleCheckBox = (key, checked) => {

        var updatedUser = user;

        if (checked) {
            saveUserArea({ userId: user.id, area: key })
            updatedUser.areas.push({ area: key });
        }
        else {
            removeUserArea({ userId: user.id, area: key })
            updatedUser.areas = updatedUser.areas.filter(item => item.area !== key);
        }
        setUser(updatedUser);
    }

    const isChecked = (key) => {
        return user?.areas?.some(item => item.area === key);
    }

    useEffect(() => {
        if (user) {
            setProfessionalCode(user?.professionalCode)
            setCPF(user?.cpf)
            setAbout(user?.about)
        }

    }, [user])

    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <div className={$.block}>
                    <div className={$.blockBody}>
                        <div className={$.blockHeader}>
                            <div className={$.blockTitle}>
                                IMAGEM DE CAPA
                            </div>
                        </div>
                        <div className={$.infos}>
                            <div className={$.cover} style={{ backgroundImage: 'url(' + process.env.REACT_APP_FILE_SERVER + user.cover + ')' }} onClick={() => handleClickUpload()}>
                                <Loading enabled={uploading}>
                                    <input type="file" hidden ref={fileRef} accept=".jpeg, .jpg, .png" onChange={(e) => handleUpload(e.target)} />
                                </Loading>
                            </div>
                            <div className={$.uploadInfos}>
                                <div>Largura Recomendada: 375px</div>
                                <div>Altura Recomendada: 120px</div>
                                <div>Apenas *.jpeg, *.jpg, *.png</div>
                                <div>Tamanho maximo de 1MB.</div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className={$.blockBody}>
                        <div className={$.blockHeader}>
                            <div className={$.blockTitle}>
                                REGISTRO DO PROFISSONAL
                            </div>
                        </div>
                        <div className={$.infos}>
                            <div className={$.field}>
                                <TextField title='CPF' get={cpf} set={setCPF} format='cpf' />
                            </div>
                            <div className={$.field}>
                                <TextField title='Registro OAB' get={professionalCode} set={setProfessionalCode} format='oab' />
                            </div>
                            <div className={$.textarea}>
                                <TextField title='Sobre' type='textarea' rows={9} get={about} set={setAbout} />
                                <div className={$.hint}>
                                    <FontAwesomeIcon icon={faInfoCircle} className={$.hintIcon} />
                                    Forneça detalhes sobre seu histórico profissional, tempo de atuação na carreira, especialidades e outras informações relevantes. Os usuários usarão essas informações para tomar decisões informadas ao escolher um profissional.
                                </div>
                            </div>
                            <div className={$.command}>
                                <Button text={'Salvar'} color='var(--primary_main)' textColor='var(--white)' onClick={handleButton} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={$.block}>
                    <div className={$.blockBody}>
                        <div className={$.blockHeader}>
                            <div className={$.blockTitle}>
                                ÁREA DE ATUAÇÃO
                            </div>
                        </div>
                        <div className={$.infos}>
                            <div className={$.field}>
                                <div className={$.list}>
                                    {
                                        areas?.map(
                                            item => <div className={$.item} key={item.key}>
                                                <CheckBox checked={isChecked(item.key)} value={item.key} onChange={handleCheckBox} />
                                                <div className={$.text}>{item.value}</div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div >)
}