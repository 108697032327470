import $ from './ChangePassword.module.css'
import { React, useContext } from 'react';
import { UserContext } from '../../Context/UserContext';
import Button from '../../Components/Button/Button';
import { sendPasswordResetEmail } from '../../Firebase';
import { v4 as uuidv4 } from 'uuid';
import { AlertContext } from '../../Context/AlertContext';

export default function ChangePassword() {

    const { user } = useContext(UserContext);
    const { alerts, setAlerts } = useContext(AlertContext);

    const handleButton = () => {
        if (!user?.email)
            return;

        sendPasswordResetEmail(user.email).then((success) => {
            if (success)
                showAlert('success', 'E-mail de alteração de senha enviado para sua caixa de entrada.')
            else
                showAlert('error', 'Ocorreu um erro ao enviar email, tente novamente.')
        })
    }

    const showAlert = (type, message) => {
        const id = uuidv4();
        const newAlert = { id, message, type };
        setAlerts([...alerts, newAlert]);
    };

    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <div className={$.infos}>
                    <div className={$.block}>
                        <div className={$.blockBody}>
                            <div className={$.blockHeader}>
                                <div className={$.blockTitle}>
                                    ALTERAR SENHA
                                </div>
                            </div>
                            <div className={$.hint}>
                                Nosso sistema de segurança é de extrema importância para nós. Por esse motivo, não armazenamos senhas em nossas bases de dados. Utilizamos os serviços do Google Firebase para garantir a sua proteção. Caso você precise recuperar a sua senha, enviaremos um e-mail com as instruções necessárias para a redefinição.
                            </div>
                            <div className={$.command}>
                                <Button text={'Enviar Redefinição'} color='var(--primary_main)' textColor='var(--white)' onClick={handleButton} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}