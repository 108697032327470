import { React } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from './Badge.module.css';

export default function Badge(p) {

    const { id = 'badge', icon, color = '#FFFFFF', value = 0, limit = 99999, onClick, children } = p;

    const text = () => {
        if (value > limit)
            return limit + '+'
        return value;
    }

    return (
        <div className={$.container}>
            <div className={$.wrapper} onClick={() => onClick && onClick()} id={id}>
                {icon && <FontAwesomeIcon icon={icon} style={{ color: color }} className={$.icon} />}
                {value > 0 && <div className={$.circle}>{text()}</div>}
            </div>
            {children && children}
        </div >
    )
}