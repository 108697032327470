const server = process.env.REACT_APP_SERVER;

export const saveArea = async (area) => {
    const response = await fetch(server + 'area', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(area)
    });

    if (!response.ok)
        throw new Error('Erro ao cadastrar o usuário');
};

export const searchAreas = async (filters) => {
    try {
        const response = await fetch(server + 'area/search', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(filters)
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error('Erro ao buscar usuários');
        }
    } catch (error) {
        throw new Error(error);
    }
};