import $ from './Termos.module.css'
import { React } from 'react';

export default function Termos(params) {

    const { title, children } = params;

    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <div className={$.block}>
                    <div className={$.blockBody}>
                        <div className={$.blockHeader}>
                            <div className={$.blockTitle}>
                                {title}
                            </div>
                        </div>
                        <div className={$.infos}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div >)
}