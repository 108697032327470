import React from 'react';
import $ from './TermoServico.module.css';

export default function TermoServico() {
    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <h1>Termos de Serviço</h1>
                <p>Bem-vindo(a) à nossa plataforma online de tira-dúvidas jurídicas e pesquisa de advogados! Ao acessar e utilizar nossos serviços, você concorda com os termos e condições descritos abaixo. Se você não concordar com esses termos, por favor, não use nossos serviços.</p>
                <h2>1. Objetivo da Plataforma</h2>
                <p>Nossa plataforma tem como objetivo fornecer informações jurídicas precisas e confiáveis por meio de tecnologia de inteligência artificial. Além disso, a plataforma também oferece uma ferramenta para pesquisa de advogados cadastrados em nossa base de dados.</p>
                <h2>2. Uso dos Serviços</h2>
                <p>Ao usar nossos serviços, você declara ser maior de idade e capaz de celebrar contratos. Você também concorda em fornecer informações precisas e atualizadas sobre si mesmo, incluindo seu nome, endereço de e-mail e informações de pagamento, se necessário.</p>
                <p>Você não pode usar nossos serviços para fins ilegais, fraudulentos ou que violem os direitos de terceiros. Também não é permitido usar nossos serviços de maneira que possa danificar, desabilitar, sobrecarregar ou prejudicar a plataforma ou seus usuários.</p>
                <p>Reservamos o direito de suspender ou encerrar sua conta se você violar estes termos de serviço ou se sua conduta prejudicar a plataforma ou seus usuários.</p>
                <h2>3. Responsabilidade do Usuário</h2>
                <p>Ao usar nossos serviços, você reconhece que é o único responsável por suas ações e conduta. Você também concorda em não responsabilizar a plataforma ou seus parceiros por quaisquer danos, perdas ou reclamações decorrentes de seu uso dos serviços.</p>
                <h2>4. Direitos de Propriedade</h2>
                <p>Todos os direitos de propriedade intelectual relacionados à plataforma e seus serviços são de nossa propriedade ou de nossos licenciadores. Você não tem permissão para usar nossas marcas registradas ou direitos autorais sem nossa autorização prévia.</p>
                <h2>5. Limitação de Responsabilidade</h2>
                <p>A plataforma e seus parceiros não serão responsáveis por quaisquer danos indiretos, incidentais, especiais ou consequentes decorrentes do uso dos serviços. Além disso, não garantimos que nossos serviços serão ininterruptos, seguros ou livres de erros.</p>
                <h2>6. Disposições Gerais</h2>
                <p>Estes termos de serviço serão regidos e interpretados de acordo com as leis brasileiras. Qualquer disputa ou litígio decorrente destes termos de serviço será resolvido exclusivamente pelos tribunais brasileiros.</p>
                <p>Reservamos o direito de atualizar estes termos de serviço a qualquer momento, sem aviso prévio. Se continuarmos a fornecer nossos serviços após a atualização dos termos, isso significará que você</p>
            </div>
        </div>
    )
}