import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from './Infos.module.css'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import TextField from '../../Components/TextField/TextField';
import Button from '../../Components/Button/Button';
import { sendEmail } from '../../Services/Email';
import { UserContext } from '../../Context/UserContext';
import { React, useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AlertContext } from '../../Context/AlertContext';

export default function Infos() {

    const { user } = useContext(UserContext);
    const { alerts, setAlerts } = useContext(AlertContext);
    const [subject, setSubject] = useState();
    const [text, setText] = useState();

    const send = () => {

        if (!subject) {
            showAlert('info', 'O assunto da mensagem precisa ser informado.')
            return;
        }

        if (!text) {
            showAlert('info', 'O conteudo da mensagem precisa ser informado.')
            return;
        }

        sendEmail(user.email, subject, text);
        setSubject('');
        setText('');
        showAlert('success', 'Sua mensagem foi enviada com sucesso, por favor aguarde que logo retornaremos o contato.')
    }

    const showAlert = (type, message) => {
        const id = uuidv4();
        const newAlert = { id, message, type };
        setAlerts([...alerts, newAlert]);
    };


    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <div className={$.block}>
                    <div className={$.blockBody}>
                        <div className={$.blockHeader}>
                            <div className={$.blockTitle}>
                                CANAIS DE ACESSO
                            </div>
                        </div>
                        <div className={$.infos}>
                            <div className={$.title}>
                                É um prazer receber sua mensagem e estamos prontos para ajudá-lo no que for preciso. Por favor, preencha o formulário ao lado com suas dúvidas, comentários ou sugestões e teremos o maior prazer em retornar o mais breve possível. O Legify valoriza a satisfação dos nossos clientes e estamos sempre comprometidos em fornecer um serviço de qualidade.
                            </div>
                            <div className={$.social}>
                                <div className={$.socialLine}><FontAwesomeIcon icon={faEnvelope} />contato@legify.com.br</div>
                                <div className={$.socialLine}><FontAwesomeIcon icon={faPhone} />+55 (41) 99900-0017</div>
                                <div className={$.socialLine}><FontAwesomeIcon icon={faInstagram} /><a href={'https://facebook.com.br/'}>https://facebook.com.br/</a></div>
                                <div className={$.socialLine}><FontAwesomeIcon icon={faFacebook} /><a href={'https://instagram.com.br/'}>https://instagram.com.br/</a></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={$.block}>
                    <div className={$.blockBody}>
                        <div className={$.blockHeader}>
                            <div className={$.blockTitle}>
                                FALE CONOSCO
                            </div>
                        </div>
                        <div className={$.infos}>
                            <div className={$.field}>
                                <TextField title={'Nome'} get={user.name} disabled />
                            </div>
                            <div className={$.field}>
                                <TextField title={'Assunto'} get={subject} set={setSubject} />
                            </div>
                            <div className={$.field}>
                                <TextField title={'Digite sua mensagem aqui'} type={'textarea'} rows={7} get={text} set={setText} />
                            </div>
                            <div className={$.button}>
                                <Button text={'Enviar'} color={'var(--primary_main'} onClick={() => send()} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >)
}