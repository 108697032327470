import { faArrowLeft, faBars } from '@fortawesome/free-solid-svg-icons';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Badge from '../Components/Badge/Badge';
import { UserContext } from '../Context/UserContext';
import $ from './ContentMaster.module.css'
import SideMenu from './SideMenu'
import TopMenu from './TopMenu'
import { logout } from '../Firebase';
import firebase from 'firebase/compat/app';
import { searchSubscription } from '../Services/Stripe';
import LoadingPage from './_components/LoadingPage';
import Question from '../Pages/Question/Question';
import Avatar from '../Components/Avatar/Avatar';
import Robot from '../Images/robot.webp';
import { saveUser, searchUsers } from '../Services/Database/User';
import { React, useMemo } from 'react';

export default function ContentMaster(p) {

    const { children } = p;

    const navigate = useNavigate();
    const [menu, setMenu] = useState(true);
    const [stretch, setStretch] = useState(true);
    const [mobile, setMobile] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error] = useState();
    const [expand, setExpand] = useState(false);
    const [hint, setHint] = useState(true);
    const imagemRobot = useMemo(() => Robot, []);


    const { setUser } = useContext(UserContext);

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged(authUser => {
            if (authUser) {
                searchUsers({ email: authUser.email }).then((result) => {
                    if (result.length > 0) {
                        const user = result[0];
                        user.picture = user.picture ? user.picture : 'user.png';
                        user.cover = user.cover ? user.cover : 'cover.jpg';
                        if (user.role === 'Advogado') {
                            searchSubscription(user.email, user.name).then((reg) => {
                                if (reg) {
                                    if (user.customerId !== reg.customer) {
                                        user.customerId = reg.customer;
                                        saveUser(user);
                                    }
                                    user.subscription = reg.subscription;
                                }
                                setUser(user);
                                setLoading(false);
                            })
                        }
                        else {
                            setUser(user);
                            setLoading(false);
                        }
                    }
                    else {
                        logout();
                        navigate('/')
                    }
                })
            } else {
                logout();
                navigate('/')
            }
        });

        return () => unsubscribe();
    }, []);


    useEffect(() => {

        const handleResize = () => {

            if (window.innerWidth <= 1024) {
                setMenu(false)
                setStretch(false)
                setMobile(true)
            }
            else {
                setMenu(true)
                setStretch(true)
                setMobile(false)
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const closeMenu = () => {
        if (mobile)
            setMenu(false)
    }

    return (
        loading ?
            <LoadingPage error={error} />
            :
            <div className={$.container}>
                <div className={$.hide} style={{ left: menu ? '220px' : '25px' }}>
                    <Badge icon={menu ? faArrowLeft : faBars} color='var(--texto2)' onClick={() => { setMenu(!menu) }} />
                </div>
                <div className={$.menu} style={{ left: menu ? '0px' : '-301px' }}>
                    <SideMenu closeMenu={closeMenu} />
                </div>
                <div className={$.content} style={{ left: stretch && menu ? '300px' : '0px' }}>
                    <TopMenu />
                    {children}
                </div>
                {
                    window.location.pathname !== '/question' && !mobile &&
                    <div className={$.chat}>
                        <div className={$.chatAction} onClick={() => { setExpand(!expand); setHint(false); }}>
                            {
                                hint &&
                                <div className={$.chatHint}>Oi! Caso você tenha alguma dúvida estou aqui para ajudar! Clique aqui e tire suas duvidas agora mesmo.</div>
                            }
                            <Avatar picture={imagemRobot} size={50} />
                        </div>
                        {
                            expand &&
                            <div className={$.chatBody}>
                                <Question presentation={true} onClose={() => { setExpand(!expand) }} />
                            </div>
                        }
                    </div>
                }
            </div>

    )

}