import React from 'react'
import $ from './SideMenu.module.css'
import Logo from './../Images/logo_rocket.webp'
import Avatar from '../Components/Avatar/Avatar'
import { useContext } from 'react'
import { UserContext } from '../Context/UserContext'
import MenuGroup from '../Components/Menu/MenuGroup'
import Menu from '../Components/Menu/Menu'
import { faUser, faQuestionCircle, faScaleBalanced, faEnvelope, faCog, faChartColumn, faComments, faFolderOpen, faPrint, faCalendarDays, faUserCheck, faDollarSign, faUserGroup, faGavel } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom'
import { canAccess, genderRole } from '../Utils/Professional';
import { useMemo } from 'react'

export default function SideMenu(params) {

    const { closeMenu } = params;

    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const imagemLogo = useMemo(() => Logo, []);


    const profile = () => {
        navigate('/profile')
    }

    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <img alt='Legify Logo' className={$.logo} src={imagemLogo} width='40' height='40' />
                <div className={$.profileCard} onClick={() => profile()}>
                    <Avatar name={user?.name} picture={process.env.REACT_APP_FILE_SERVER + user?.picture} />
                    <div className={$.infos}>
                        <div className={$.infosName} id={'name'}>{user?.name}</div>
                        {
                            user?.role === 'Advogado' &&
                            <div className={$.infosRole}>{genderRole(user?.sex)}</div>
                        }
                    </div>
                </div>
                <MenuGroup title={'GERAL'}>
                    {canAccess(user?.role) &&
                        <Link to={'/dashboard'} onClick={() => closeMenu()}>
                            <Menu icon={faChartColumn} title={'Dashboard'} link={'dashboard'} active={window.location.pathname === '/dashboard'} />
                        </Link>
                    }
                    <Link to={'/professionals'} onClick={() => closeMenu()}>
                        <Menu icon={faScaleBalanced} title={'Advogados'} link={'professionals'} active={window.location.pathname === '/professionals'} />
                    </Link>
                    <Link to={'/question'} onClick={() => closeMenu()}>
                        <Menu icon={faComments} title={'Perguntas'} link={'question'} active={window.location.pathname === '/question'} />
                    </Link>
                    <Link to={'/profile'} onClick={() => closeMenu()}>
                        <Menu icon={faUser} title={'Perfil'} link={'profile'} active={window.location.pathname === '/profile'} />
                    </Link>
                </MenuGroup>
                <MenuGroup title={'GERENCIAMENTO'}>
                    <Link to={'/clientes'} onClick={() => closeMenu()}>
                        <Menu icon={faUserGroup} title={'Gestão de Clientes'} link={'clientes'} active={window.location.pathname === '/clientes'} />
                    </Link>
                    <Link to={'/processos'} onClick={() => closeMenu()}>
                        <Menu icon={faGavel} title={'Gerenciamento de Processos'} link={'processos'} active={window.location.pathname === '/processos'} />
                    </Link>
                    <Link to={'/documentos'} onClick={() => closeMenu()}>
                        <Menu icon={faFolderOpen} title={'Gestão de Documentos'} link={'documentos'} active={window.location.pathname === '/documentos'} />
                    </Link>
                    <Link to={'/financeiro'} onClick={() => closeMenu()}>
                        <Menu icon={faDollarSign} title={'Gestão Financeira'} link={'financeiro'} active={window.location.pathname === '/financeiro'} />
                    </Link>
                    <Link to={'/agenda'} onClick={() => closeMenu()}>
                        <Menu icon={faCalendarDays} title={'Agenda e Prazos'} link={'agenda'} active={window.location.pathname === '/agenda'} />
                    </Link>
                    <Link to={'/monitoramento'} onClick={() => closeMenu()}>
                        <Menu icon={faUserCheck} title={'Monitoriamento'} link={'monitoramento'} active={window.location.pathname === '/monitoramento'} />
                    </Link>
                    <Link to={'/relatorios'} onClick={() => closeMenu()}>
                        <Menu icon={faPrint} title={'Relatorios'} link={'relatorios'} active={window.location.pathname === '/relatorios'} />
                    </Link>
                </MenuGroup>
                <MenuGroup title={'LEGIFY'}>
                    <Link to={'/about'} onClick={() => closeMenu()}>
                        <Menu icon={faQuestionCircle} title={'Sobre'} link={'profile'} active={window.location.pathname === '/about'} />
                    </Link>
                    <Link to={'/contact'} onClick={() => closeMenu()}>
                        <Menu icon={faEnvelope} title={'Contato'} link={'contact'} active={window.location.pathname === '/contact'} />
                    </Link>
                </MenuGroup>
                {
                    user?.role === 'Administrador' &&
                    <MenuGroup title={'AREA RESTRITA'}>
                        <Link to={'/administration'} onClick={() => closeMenu()}>
                            <Menu icon={faCog} title={'Administração'} link={'administration'} active={window.location.pathname === '/administration'} />
                        </Link>
                    </MenuGroup>
                }
            </div>
        </div >
    )

}