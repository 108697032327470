import { React } from 'react';
import $ from './AccordionSummary.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function AccordionSummary(p) {

    const { title, icon, action } = p;

    return (
        <div className={$.container} onClick={() => action()}>
            {title}
            <FontAwesomeIcon className={$.icon} icon={icon} />
        </div>
    )
}