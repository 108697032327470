import { React, useContext, useEffect, useRef, useState } from 'react';
import TextField from '../../Components/TextField/TextField';
import Accordion from '../../Components/Accordion/Accordion';
import Button from '../../Components/Button/Button';
import $ from './Question.module.css';
import { execute } from '../../Services/OpenAI';
import { faKeyboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserContext } from '../../Context/UserContext';
import { v4 as uuidv4 } from 'uuid';
import { getDistanceBetweenLocations } from '../../Services/Maps';
import { findJuridicalAreas } from '../../Utils/Question';
import { getSubscription } from '../../Services/Stripe';
import Card from '../Professionals/Card';
import { getArea } from '../../Utils/Professional';
import { insertQuestion, searchQuestions } from '../../Services/Database/Question';
import { searchUsers } from '../../Services/Database/User';
import { saveArea, searchAreas } from '../../Services/Database/Area';
import Dialog from '../../Components/Dialog/Dialog';
import Viewer from '../Professionals/Viewer';
import { Helmet } from 'react-helmet';

const init = 'Como posso ajudá-lo(a) hoje? Sinta-se à vontade para fazer sua pergunta ou expressar sua dúvida, estou aqui para ajudar no que puder!';
const def = 'Pergunte aqui.';

const format = (text) => {
    const lines = text.split('\n');
    return (
        <div>
            {
                lines.map((line, index) => (
                    index === 0 ? <div key={index}>{line}</div> : <p key={index}>{line}</p>
                ))
            }
        </div>
    );
}

export default function Question(params) {

    const { presentation = false, disabled = false, onClose } = params;

    const { user } = useContext(UserContext);
    const [search, setSearch] = useState();
    const [loading, setLoading] = useState(false);
    const [professionals, setProfessionals] = useState([]);
    const [history, setHistory] = useState([]);
    const [expand, setExpand] = useState();
    const scroll = useRef(null);
    const ref = useRef(null);
    const [chat, setChat] = useState([
        {
            id: uuidv4(),
            sender: 'assistant',
            message: format(init),
            date: (new Date().getHours().toString().padStart(2, '0') + ':' + new Date().getMinutes().toString().padStart(2, '0'))
        },
    ]);

    const send = async () => {

        if (search && !disabled) {
            setSearch('')

            setLoading(true);

            setChat(prevChat => {
                const newChat = [...prevChat, {
                    id: uuidv4(),
                    sender: 'user',
                    message: search,
                    messageClear: search,
                    date: (new Date().getHours().toString().padStart(2, '0') + ':' + new Date().getMinutes().toString().padStart(2, '0'))
                }];

                setChat(newChat);

                var prompt = [];
                newChat.forEach((p) => {
                    if (p.messageClear && p.sender !== 'sugestion')
                        prompt.push({ role: p.sender, content: p.messageClear });
                })

                execute(prompt, user.role)
                    .then(r => {
                        if (r) {
                            const newResponse = [...newChat, {
                                id: uuidv4(),
                                sender: 'assistant',
                                message: format(r),
                                messageClear: r,
                                date: (new Date().getHours().toString().padStart(2, '0') + ':' + new Date().getMinutes().toString().padStart(2, '0'))
                            }];
                            setChat(newResponse);

                            const foundAreas = findJuridicalAreas(r);
                            if (foundAreas && foundAreas.length > 0) {
                                foundAreas.forEach((item) => {
                                    var foundSugestions = showSugestions(item);
                                    if (foundSugestions.length > 0) {
                                        const newSugestion = [...newResponse, {
                                            id: uuidv4(),
                                            sender: 'sugestion',
                                            message: foundSugestions,
                                            type: getArea(item),
                                            date: (new Date().getHours().toString().padStart(2, '0') + ':' + new Date().getMinutes().toString().padStart(2, '0'))
                                        }];
                                        setChat(newSugestion);
                                    }
                                    searchAreas({ name: item }).then((filter) => {
                                        var updValue = filter.length > 0 ? filter[0] : { name: item, total: 0 };
                                        updValue.total = (updValue.total + 1);
                                        saveArea(updValue)
                                    })
                                })
                            }
                            insertQuestion({ userId: user.id, question: search, answer: r, date: new Date().getTime() });
                        }
                        setLoading(false);
                    })
                    .catch(error => {
                        setLoading(false);
                        console.error(error);
                        return prevChat;
                    });
            });
        }
    };

    const loadProfessionals = () => {
        searchUsers({ role: 'Advogado' }).then((data) => {
            if (data) {
                var newList = [];
                data.forEach((item) => {
                    var isPremium = false;
                    if (item.customerId && item.validated) {
                        isPremium = getSubscription(item.customerId)
                        var dis = getDistanceBetweenLocations({ lat: item.lat, lon: item.lon }, { lat: user?.lat, lon: user?.lon });
                        item.isPremium = isPremium;
                        item.distance = (dis * 1.3).toFixed(2)
                        item.distance = item.distance >= 0 ? item.distance : (0).toFixed(2);
                        newList.push(item);
                    }
                })
                setProfessionals(newList);
            }
        });
    }

    const loadHistory = () => {
        searchQuestions({ userId: user.id }).then((data) => {
            if (data) {
                setHistory(data);
            }
        });
    }

    const showSugestions = (area) => {
        var nearList = professionals.sort((a, b) => { return a.distance - b.distance });
        if (nearList) {
            var match = []
            nearList.forEach((item) => {
                if (item.areas && item.areas.some((a) => a.area === area)) {
                    match.push(item)
                }
            })
            return match.slice(0, 6);
        }
        return null
    }

    const handleKeyPress = (event) => {
        if (event.keyCode === 13) {
            send();
        }
    }

    const formatLetter = (text) => {
        let firstLetter = text.charAt(0).toUpperCase();
        text = firstLetter + text.slice(1);
        return text;
    }

    const formatAccordion = (text, date) => {

        text = formatLetter(text);

        return <div style={{ display: 'flex' }}>
            <div style={{ flex: 1, textAlign: 'left' }}>
                <div style={{ fontSize: 14, color: 'var(--texto1)' }}>{text.slice(0, 90)}{text.length > 90 && '...'}</div>
                <div style={{ fontSize: 12, color: 'var(--texto2)' }}>{date}</div>
            </div>
        </div>;
    }

    useEffect(() => {
        const element = scroll.current;
        const handleHeightChange = () => {
            element.scrollTop = element.scrollHeight;
        };
        element.addEventListener("DOMSubtreeModified", handleHeightChange);

        return () => {
            element.removeEventListener("DOMSubtreeModified", handleHeightChange);
        };

    }, [scroll]);

    useEffect(() => {
        const handleClickOutside = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClose && onClose()
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    useEffect(() => {
        loadProfessionals();
        loadHistory();
    }, [])

    return (
        <>
            <Helmet>
                <title>Legify | Perguntas</title>
            </Helmet>
            <div className={$.container} ref={ref}>
                <div className={presentation ? $.questionPopWrapper : $.questionWrapper}>
                    <div className={$.layer}>
                        <div className={$.wrapper} >
                            <div className={$.chatContainer} style={{ marginBottom: presentation ? '0px' : '15px', marginLeft: presentation ? '0px' : '20px' }}>
                                <div className={$.chatWrapper} >
                                    <div className={$.chatBody}>
                                        <div className={$.chatScroll}>
                                            <div className={$.chatScrollArea} ref={scroll}>
                                                {
                                                    chat?.map(x =>
                                                        <div className={`${$.chatLine} ${x.sender === 'user' && $.client}`} key={x.id}>
                                                            <div className={$.chatMessageName}>
                                                                {x.sender === 'assistant' && 'Inteligencia Artificial'}
                                                                {x.sender === 'user' && user.name}
                                                                {x.sender === 'sugestion' && <div>Advogados de {x.type} perto de você</div>}
                                                            </div>
                                                            {
                                                                x.sender !== 'sugestion' ?
                                                                    <div className={$.chatMessageWrapper}>
                                                                        <div className={$.chatMessage} style={{ background: (x.sender === 'assistant' ? 'var(--background)' : 'var(--primary_main)') }}>{x.message}</div>
                                                                        <div className={$.chatDate}>{x.date}</div>
                                                                    </div> :
                                                                    <div className={$.chatMessageSuggestion}>
                                                                        {
                                                                            x.message.map((item) =>
                                                                                <div key={item.id} className={$.suggestion} onClick={() => setExpand(item)}>
                                                                                    <Card pro={item} color={'var(--background)'} />
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                            }
                                                        </div>
                                                    )
                                                }
                                                {
                                                    loading && <div className={$.chatLine}>
                                                        <div className={$.chatMessageWrapper}>
                                                            <div className={$.chatMessage} style={{ background: 'var(--background)' }}>
                                                                <div className={$.loading}>
                                                                    <div className={$.dotFlashing} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                }
                                            </div>
                                            {
                                                <div className={$.chatInput}>
                                                    <FontAwesomeIcon className={$.chatInputIcon} icon={faKeyboard} />
                                                    <div className={$.chatInputText}>
                                                        <div className={$.chatInputForm}>
                                                            <TextField border='none' get={search} set={setSearch} placeholder={def} onKeyDown={handleKeyPress} limit={500} />
                                                        </div>
                                                        <div className={$.chatInputButton}>
                                                            <Button text={'Enviar'} color='var(--primary_main)' onClick={send} />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                !presentation &&
                                <div className={$.historyContainer}>
                                    <div className={$.historyWrapper}>
                                        <div className={$.historyTitle}>HISTORICO DE PERGUNTAS</div>
                                        <div className={$.historyBody}>
                                            {
                                                history?.map((item) =>
                                                    <Accordion key={item.date} title={formatAccordion(item.question, new Date(item.date).toLocaleDateString('pt-br'))}>
                                                        <div className={$.historyQuestion}>
                                                            <div className={$.historyHeader}>PERGUNTA</div>
                                                            {formatLetter(item.question)}
                                                        </div>
                                                        <div>
                                                            <div className={$.historyHeader}>RESPOSTA</div>
                                                            {item.answer}
                                                        </div>
                                                    </Accordion>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {expand && <Dialog onBlur={() => setExpand()} closeButton><Viewer professional={expand} /></Dialog>}
            </div >
        </>
    )

}
