
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';
import { removeProperties } from './Utils/Properties';

export const config = {
    apiKey: "AIzaSyC2A_FTZA1BH5hGkght433OreurWO9gKpY",
    authDomain: "advogando-2a8ad.firebaseapp.com",
    databaseURL: "https://advogando-2a8ad-default-rtdb.firebaseio.com",
    projectId: "advogando-2a8ad",
    storageBucket: "advogando-2a8ad.appspot.com",
    messagingSenderId: "532218963232",
    appId: "1:532218963232:web:e493d6fe410a07a19b6345",
    measurementId: "G-7NZ0P1HYD9"
};

export function pushDataToFirebase(table, id, data) {
    return new Promise((resolve, reject) => {
        if (!isAuthenticated()) {
            return;
        }

        const database = firebase.database();
        const ref = database.ref(table);
        const newRef = ref.child(id).push(); // Use o método push() para gerar uma chave automaticamente
        newRef.set(data, (error) => {
            if (error) {
                console.error('Error saving data:', error);
                reject(error);
            } else {
                resolve(newRef.key); // Retorna a chave gerada
            }
        });
    });
}

export function saveDataToFirebase(table, id, data) {
    return new Promise((resolve, reject) => {
        if (!isAuthenticated()) {
            return;
        }

        const database = firebase.database();
        const ref = database.ref(table);
        const newRef = ref.child(id);
        newRef.set(data, (error) => {
            if (error) {
                console.error('Error saving data:', error);
                reject(error);
            } else {
                resolve(newRef.key); // Retorna o ID gerado
            }
        });
    });
}

export function deleteDataFromFirebase(table, key) {

    if (!isAuthenticated())
        return;

    const database = firebase.database();
    const ref = database.ref(`${table}/${key}`);

    ref.remove((error) => {
        if (error) {
            console.error('Error deleting data:', error);
            return false;
        }
        return true;
    });
}

export function updateDataInFirebase(table, key, updatedData) {

    if (!isAuthenticated())
        return;

    const database = firebase.database();
    const ref = database.ref(table);

    updatedData = removeProperties(updatedData, ['id', 'password', 'subscription'])

    ref.child(key).update(updatedData, (error) => {
        if (error) {
            return false;
        }
        return true;
    });
}

export function getCountFromFirebaseTable(table) {
    const database = firebase.database();
    const ref = database.ref(table);

    return new Promise((resolve, reject) => {
        ref.once('value', (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const count = Object.keys(data).length;
                resolve(count);
            } else {
                resolve(0);
            }
        }, (error) => {
            console.error('Error reading data:', error);
            reject(error);
        });
    });
}

export function readDataFromFirebase(table, cache = false) {

    const cacheKey = `${table}_all`; // Chave única para o cache

    if (cache) {
        const cachedData = sessionStorage.getItem(cacheKey); // Verificar se os dados estão no cache
        if (cachedData) {
            // Se os dados estiverem no cache, retorná-los
            return Promise.resolve(JSON.parse(cachedData));
        }
    }

    const database = firebase.database();
    const ref = database.ref(table);

    return new Promise((resolve, reject) => {
        ref.once('value', (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const dataList = Object.keys(data).map(key => {
                    return { id: key, ...data[key] }
                });
                cache && sessionStorage.setItem(cacheKey, JSON.stringify(dataList)); // Armazenar os dados no cache
                resolve(dataList);
            } else {
                resolve([]);
            }
        }, (error) => {
            console.error('Error reading data:', error);
            reject(error);
        });
    });
}

export function filterDataByValue(table, field, value, cache = false) {

    const cacheKey = `${table}_${field}_${value}`; // Chave única para o cache

    if (cache) {
        const cachedData = sessionStorage.getItem(cacheKey); // Verificar se os dados estão no cache
        if (cachedData) {
            // Se os dados estiverem no cache, retorná-los
            return Promise.resolve(JSON.parse(cachedData));
        }
    }

    const database = firebase.database();
    const ref = database.ref(table);

    const query = ref.orderByChild(field).equalTo(value);

    return new Promise((resolve, reject) => {
        query.once('value', (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const dataList = Object.keys(data).map(key => {
                    return { id: key, ...data[key] }
                });
                if (dataList.length > 1) {
                    cache && sessionStorage.setItem(cacheKey, JSON.stringify(dataList)); // Armazenar os dados no cache
                    resolve(dataList);
                } else {
                    cache && sessionStorage.setItem(cacheKey, JSON.stringify(dataList[0])); // Armazenar os dados no cache
                    resolve(dataList[0]);
                }
            } else {
                resolve(null);
            }
        }, (error) => {
            console.error('Error filtering data:', error);
            reject(error);
        });
    });
}

export function createAccount(email, password) {
    return firebase.auth().createUserWithEmailAndPassword(email, password)
        .then((userCredential) => {
            userCredential.user.sendEmailVerification();
            const user = userCredential.user;
            return user;
        })
        .catch((error) => {
            throw error;
        });
}

export function deleteAccount() {
    return firebase.auth().currentUser.delete();
}

export function signInWithEmailAndPassword(email, password) {
    return firebase.auth().signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
            const user = userCredential.user;
            if (user.emailVerified)
                return user;
            throw ('E-mail não verificado');
        })
        .catch((error) => {
            if (error.code === 'auth/wrong-password') {
                throw ('Senha invalida');
            } else {
                throw (error);
            }
        });
}

export function signInWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider)
        .then(userCredential => {
            return userCredential;
        })
        .catch((error) => {
            if (error.code === 'auth/wrong-password')
                throw ('Senha invalida');
            if (error.code === 'auth/account-exists-with-different-credential')
                throw ('Essa conta já está associada a outro provedor de entrada');
            if (error.code === 'auth/popup-closed-by-user')
                throw ('');
            throw (error);
        });
}

export function signInWithFacebook() {
    const provider = new firebase.auth.FacebookAuthProvider();
    return firebase.auth().signInWithPopup(provider)
        .then(userCredential => {
            return userCredential;
        })
        .catch((error) => {
            if (error.code === 'auth/wrong-password')
                throw ('Senha invalida');
            if (error.code === 'auth/account-exists-with-different-credential')
                throw ('Essa conta já está associada a outro provedor de entrada');
            if (error.code === 'auth/popup-closed-by-user')
                throw ('');
            throw (error);
        });
}

export function isAuthenticated() {
    const currentUser = firebase.auth().currentUser;
    return currentUser;
}

export function sendPasswordResetEmail(email) {
    return firebase.auth().sendPasswordResetEmail(email)
        .then(() => {
            return true;
        })
        .catch((error) => {
            // Tratar erros de recuperação de senha
            throw (error);
        });
}

export function logout() {
    sessionStorage.clear();
    firebase.auth().signOut();
}

export function sendNotification(userId, message, picture = 'legify.png') {
    var now = new Date().getTime();
    pushDataToFirebase('notifications', userId, { picture, message, read: false, date: now })
}
