export const USUARIO_SENHA_INCORRETOS = 'Nome de usuário ou senha incorretos. Por favor, tente novamente.';
export const SEM_CONTA_GOOGLE = 'Não encontramos uma conta do Google vinculada ao Legify.';
export const SEM_CONTA_FACEBOOK = 'Não encontramos uma conta do Facebook vinculada ao Legify.';
export const EMAIL_EM_USO = 'O email fornecido já está associado a uma conta de usuário existente.';
export const EMAIL_INVALIDO = 'O email fornecido é invalido.';
export const SENHA_FRACA = 'A senha fornecida é fraca.';
export const ERRO_CRIAR = 'Ocorreu um erro ao criar sua conta, tente novamente após alguns instantes.';
export const EMAIL_CONFIRMACAO = 'Email de confirmação enviado! Por favor, verifique sua caixa de entrada e siga as instruções para confirmar seu endereço de email.';
export const EMAIL_RECUPERACAO = 'Email de recuperação de senha enviado para sua caixa de entrada.';

