import $ from './SocialLinks.module.css'
import { React, useContext, useEffect, useState } from 'react';
import { UserContext } from '../../Context/UserContext';
import Button from '../../Components/Button/Button';
import TextField from '../../Components/TextField/TextField';

export default function SocialLinks(params) {

    const { handleChanges } = params;

    const { user } = useContext(UserContext);
    const [facebook, setFacebook] = useState();
    const [instagram, setInstagram] = useState();
    const [linkedin, setLinkedin] = useState();
    const [twitter, setTwitter] = useState();
    const [site, setSite] = useState();

    const handleButton = () => {
        var updatedUser = user;
        updatedUser.facebook = facebook;
        updatedUser.instagram = instagram;
        updatedUser.linkedin = linkedin;
        updatedUser.twitter = twitter;
        updatedUser.site = site;
        handleChanges(updatedUser, true)
    }

    useEffect(() => {

        if (user) {
            setFacebook(user.facebook)
            setInstagram(user.instagram)
            setLinkedin(user.linkedin)
            setTwitter(user.twitter)
            setSite(user.site)
        }

    }, [user])

    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <div className={$.infos}>
                    <div className={$.block}>
                        <div className={$.blockBody}>
                            <div className={$.blockHeader}>
                                <div className={$.blockTitle}>
                                    REDES SOCIAIS
                                </div>
                            </div>
                            <div className={$.field}>
                                <TextField title={'Facebook'} get={facebook} set={setFacebook} />
                            </div>
                            <div className={$.field}>
                                <TextField title={'Instagram'} get={instagram} set={setInstagram} />
                            </div>

                            <div className={$.field}>
                                <TextField title={'LinkedIn'} get={linkedin} set={setLinkedin} />
                            </div>

                            <div className={$.field}>
                                <TextField title={'Twitter'} get={twitter} set={setTwitter} />
                            </div>
                            <div className={$.field}>
                                <TextField title={'Site Profissional'} get={site} set={setSite} />
                            </div>
                            <div className={$.command}>
                                <Button text={'Salvar'} color='var(--primary_main)' textColor='var(--white)' onClick={handleButton} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}