import { React } from 'react';
import $ from './Menu.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Menu(p) {

    const { title, icon, active = false, onClick, link } = p;

    return (
        <div className={`${$.container} ${active && $.active}`}>
            <div className={$.wrapper} onClick={() => onClick && onClick(link)}>
                {icon && <FontAwesomeIcon className={$.icon} icon={icon} />}
                <span className={$.title}>{title}</span>
            </div>
        </div>
    )

}