import React from 'react';
import $ from './Seguranca.module.css';

export default function Seguranca() {
    return (
        <div className={$.container}>
            <div className={$.wrapper}> 
                <h1>Termo de Segurança</h1>
                <p>A segurança da sua informação é importante para nós. Este termo de segurança descreve as medidas que tomamos para proteger suas informações pessoais em nossa plataforma online de tira-dúvidas jurídicas e pesquisa de advogados.</p>
                <h2>1. Medidas de Segurança</h2>
                <p>Tomamos medidas razoáveis para proteger suas informações pessoais contra perda, uso indevido e acesso não autorizado, divulgação, alteração ou destruição. Essas medidas incluem, mas não se limitam a:</p>
                <ul>
                    <li>Uso de criptografia para proteger informações confidenciais</li>
                    <li>Limitação de acesso às informações pessoais apenas para funcionários ou prestadores de serviços que precisam acessá-las para fornecer nossos serviços</li>
                    <li>Uso de firewalls, antivírus e outras tecnologias de segurança para proteger a plataforma contra ameaças cibernéticas</li>
                    <li>Implementação de políticas e procedimentos de segurança para garantir a proteção das informações pessoais</li>
                </ul>
                <h2>2. Atualização e Monitoramento</h2>
                <p>Monitoramos continuamente nossa plataforma para detectar e corrigir vulnerabilidades de segurança. Também atualizamos regularmente nossos sistemas para garantir que eles estejam protegidos contra as últimas ameaças cibernéticas.</p>
                <h2>3. Proteção de Dados de Pagamento</h2>
                <p>Todas as informações de pagamento são protegidas por criptografia e transmitidas de forma segura para nossos prestadores de serviços de pagamento. Não armazenamos informações de pagamento em nossos sistemas.</p>
                <h2>4. Responsabilidade do Usuário</h2>
                <p>Você é responsável por manter a segurança de suas informações de login e senha. Não compartilhe suas informações de login com terceiros e escolha senhas fortes que incluam letras, números e caracteres especiais.</p>
                <h2>5. Notificação de Violação de Segurança</h2>
                <p>Caso ocorra uma violação de segurança que resulte no acesso não autorizado às suas informações pessoais, notificaremos você o mais rápido possível. Tomaremos todas as medidas necessárias para remediar a violação e minimizar os danos causados.</p>
                <h2>6. Mudanças neste Termo de Segurança</h2>
                <p>Podemos atualizar este termo de segurança a qualquer momento, a nosso critério. Se fizermos alterações significativas neste termo, notificaremos você por meio de um aviso na plataforma ou por e-mail. O uso continuado da plataforma após a notificação da alteração constitui sua concordância com as alterações feitas.</p>
                <h2>7. Informações de Contato</h2>
                <p>Se você tiver alguma dúvida ou preocupação sobre este termo de segurança ou sobre a segurança de suas informações pessoais, entre em contato conosco através dos seguintes meios:</p>
                <p>E-mail: [contato@legify.com.br]</p>
                <h2>8. Responsabilidade Legal</h2>
                <p>Nós cumprimos as leis e regulamentações aplicáveis relativas à segurança da informação e proteção de dados. No entanto, não podemos garantir a segurança absoluta de suas informações pessoais e não nos responsabilizamos por violações de segurança que estejam fora de nosso controle razoável.</p>
                <p>Você reconhece e concorda que é responsável por qualquer informação que fornecer à nossa plataforma e que é responsável por sua própria segurança ao usar nossa plataforma. Você concorda em nos eximir de qualquer responsabilidade relacionada à segurança ou privacidade de suas informações pessoais.</p>
                <h2>9. Aceitação deste Termo de Segurança</h2>
                <p>Ao usar nossa plataforma, você concorda com os termos deste termo de segurança. Se você não concorda com este termo, não use nossa plataforma.</p>
                <p>Este termo de segurança entra em vigor a partir da data em que você aceita os termos de uso da plataforma e continua em vigor até que seja alterado ou rescindido.</p>
            </div>
        </div>
    )
}