import $ from './Appearance.module.css'
import Switch from '../../Components/Switch/Switch';
import { React, useState } from 'react';

export default function Appearance() {

    const [refresh, setRefresh] = useState(false);

    const currentClass = document.documentElement.className;

    const change = (className, newValue) => {
        var curClass = currentClass;
        var cur = className === 'theme' ? curClass.split(' ')[0] : curClass.split(' ')[1];
        curClass = curClass.replace(cur, newValue);
        document.documentElement.className = curClass;
        localStorage.setItem('theme', curClass);
        setRefresh(!refresh);
    }

    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <div className={$.infos}>
                    <div className={$.block}>
                        <div className={$.blockBody}>
                            <div className={$.blockHeader}>
                                <div className={$.blockTitle}>
                                    CORES
                                </div>
                            </div>
                            <div className={$.command}>
                                <div className={$.commandLine}>
                                    <div className={$.commandLineLabel}>
                                        Tema Escuro
                                    </div>
                                    <div className={$.commandLineValue}>
                                        <Switch checked={document.documentElement.className?.includes('dark')} onClick={() => change('theme', currentClass.includes('dark') ? 'light' : 'dark')} />
                                    </div>
                                </div>
                                {/* <div className={$.commandLine}>
                                    <div className={$.commandLineLabel}>
                                        Cor do Tema
                                    </div>
                                    <div className={$.commandLineValue}>
                                        <Avatar color='var(--opGreen)' size={30} onClick={() => change('color', 'green')} />
                                        <Avatar color='var(--opBlue)' size={30} onClick={() => change('color', 'blue')} />
                                        <Avatar color='var(--opPurple)' size={30} onClick={() => change('color', 'purple')} />
                                        <Avatar color='var(--opYellow)' size={30} onClick={() => change('color', 'yellow')} />
                                        <Avatar color='var(--opRed)' size={30} onClick={() => change('color', 'red')} />
                                    </div>
                                </div> */}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>)
}