import { React } from 'react';
import $ from './AccordionDetails.module.css';

export default function AccordionDetails(p) {

    const { expanded, children } = p;

    return (
        <div className={$.container} >
            {expanded && <div className={$.details}>{children}</div>}
        </div>
    )
}