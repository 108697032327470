const server = process.env.REACT_APP_SERVER;

export const getAddress = (cep) => {
    return fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then(response => response.json())
        .then(data => {
            return data
        })
        .catch(error => console.error('Erro ao buscar endereço:', error));
}

export const saveImage = async (name, base64Data) => {
    const response = await fetch(server + 'upload/image', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name, extension: 'jpg', base64Data })
    });

    if (!response.ok)
        throw new Error('Erro ao cadastrar o usuário');

    return await response.json();
};

export const getImageUrl = (imageUrl) => {
    const timeStamp = new Date().getTime();
    const updatedImageUrl = `${imageUrl}?v=${timeStamp}`;
    return updatedImageUrl;
}
