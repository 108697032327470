const server = process.env.REACT_APP_SERVER;

export const insertQuestion = async (question) => {
    await fetch(server + 'question', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(question)
    });
};

export const searchQuestions = async (userId) => {
    const response = await fetch(server + 'question/search/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(userId)
    });
    const data = await response.json();
    return data;
}