import $ from './Landing.module.css';
import Logo from './../../Images/logo_rocket.webp';
import About1 from './../../Images/about1.webp';
import About2 from './../../Images/about2.webp';
import About3 from './../../Images/about3.webp';
import Cli1 from './../../Images/client1.webp';
import Cli2 from './../../Images/client2.webp';
import Leonardo from './../../Images/Photos/leonardo.webp';
import Tiago from './../../Images/Photos/tiago.webp'; 
import Rafaella from './../../Images/Photos/rafaella.webp';
import Button from '../../Components/Button/Button';
import Avatar from '../../Components/Avatar/Avatar';
import Dialog from '../../Components/Dialog/Dialog';
import { React, useState } from 'react';
import { faBan, faEarthAmericas, faHandshakeAngle, faLocationDot, faMagnifyingGlass, faQuestion, faSackDollar, faScaleBalanced, faUserTie, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import TermoServico from '../_components/TermoServico';
import Privacidade from '../_components/Privacidade';
import Seguranca from '../_components/Seguranca';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet';

export default function Landing() {

    const [menu, setMenu] = useState('home');
    const [popup, setPopup] = useState();
    const imagemLogo = useMemo(() => Logo, []);
    const imagemAbout1 = useMemo(() => About1, []);
    const imagemAbout2 = useMemo(() => About2, []);
    const imagemAbout3 = useMemo(() => About3, []);
    const imagemLeonardo = useMemo(() => Leonardo, []);
    const imagemTiago = useMemo(() => Tiago, []);
    const imagemRafaella = useMemo(() => Rafaella, []);
    const imagemCli1 = useMemo(() => Cli1, []);
    const imagemCli2 = useMemo(() => Cli2, []);


    const navigate = useNavigate();

    return (
        <>
            <Helmet>
                <meta httpEquiv="Cache-Control" content="max-age=31536000, public" />
            </Helmet>
            <div className={$.container}>
                <div className={$.wrapper}>
                    <div className={$.contentWrapper}>
                        <div className={$.menu}>
                            <img alt='Legify Logo' src={imagemLogo} width='40' height='40' className={$.menuIcon} />
                            <div className={$.menuItens}>
                                <a href='#assistencia' className={$.menuAssist}><Button text='Assistência Judiciária' textColor={menu === 'assistencia' ? 'var(--primary_main)' : 'var(--texto1)'} type='text' onClick={() => { setMenu('assistencia') }} /></a>
                                <div style={{ display: 'flex', gap: '20px' }}>
                                    <Button text='Login' color='var(--primary_main)' textColor='var(--primary_main)' type='outline' onClick={() => navigate('/login')} />
                                    <Button text='Criar Conta' color='var(--primary_main)' onClick={() => navigate('/login')} />
                                </div>
                            </div>
                        </div>
                        <div className={$.presentation}>
                            <div className={$.presentationBlock}>
                                <div className={$.presentationTitle}>Apoio Jurídico <span className={$.presentationTitleHigh}>Descomplicado</span></div>
                                <div className={$.presentationSub}>Encontre orientações úteis e confiáveis para suas dúvidas jurídicas e obtenha assessoria personalizada com nossos advogados experientes. Simplifique suas duvidas legais com nossa plataforma de assessoria jurídica baseada em inteligência artificial.</div>
                                <div>
                                    <Button text='Comece agora' color='var(--primary_main)' onClick={() => navigate('/login')} />
                                </div>
                            </div>
                            <div className={$.presentationBlockImage}>
                                <div className={$.aboutBlockImage}>
                                    <img alt='Sobre 1' src={imagemAbout1} width='567' height='567' className={$.presentationImage} loading="eager" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={$.content} id="assistencia">
                        <div className={$.contentWrapper}>
                            <div className={$.aboutInvert}>
                                <div className={$.aboutBlock}>
                                    <div className={$.aboutTitle}>Obtenha informações confiáveis sobre diversos assuntos jurídicos</div>
                                    <div className={$.aboutSub}>Com nossa inteligência artificial baseada na ChatGPT, você pode fazer perguntas e obter informações úteis sobre diversos tópicos legais.</div>
                                    <div className={$.aboutList}>
                                        <div className={$.aboutListItem}>
                                            <FontAwesomeIcon icon={faScaleBalanced} className={$.aboutListIcon} />
                                            Respostas claras e objetivas
                                        </div>
                                        <div className={$.aboutListItem}>
                                            <FontAwesomeIcon icon={faMagnifyingGlass} className={$.aboutListIcon} />
                                            Busca simplificada
                                        </div>
                                        <div className={$.aboutListItem}>
                                            <FontAwesomeIcon icon={faSackDollar} className={$.aboutListIcon} />
                                            Economize tempo e dinheiro
                                        </div>
                                    </div>
                                </div>
                                <div className={$.aboutBlockImage}>
                                    <img alt='Sobre 2' src={imagemAbout2} width='575' height='575' className={$.aboutImage} loading="eager" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={$.content}>
                        <div className={$.contentWrapper}>
                            <div className={$.about}>
                                <div className={$.aboutBlock}>
                                    <div className={$.aboutTitle}>Encontre advogados próximos para assessoria personalizada</div>
                                    <div className={$.aboutSub}>Nossa plataforma possui uma área onde você pode encontrar advogados experientes e confiáveis em sua região, para obter a consultoria personalizada.</div>
                                    <div className={$.aboutList}>
                                        <div className={$.aboutListItem}>
                                            <FontAwesomeIcon icon={faUserTie} className={$.aboutListIcon} />
                                            Profissionais prontos para atendê-lo
                                        </div>
                                        <div className={$.aboutListItem}>
                                            <FontAwesomeIcon icon={faLocationDot} className={$.aboutListIcon} />
                                            Próximos a você
                                        </div>
                                        <div className={$.aboutListItem}>
                                            <FontAwesomeIcon icon={faEarthAmericas} className={$.aboutListIcon} />
                                            Os melhores do mercado ao seu alcance
                                        </div>
                                    </div>
                                </div>
                                <div className={$.aboutBlockImage}>
                                    <img alt='Sobre 3' src={imagemAbout3} width='575' height='575' className={$.aboutImage} loading="eager" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={$.contentWrapper}>
                    <div className={$.peoples}>
                        <div className={$.peoplesTitle}>Depoimentos dos <span className={$.presentationTitleHigh}>usuários</span></div>
                        <div className={$.peoplesList}>
                            <div className={$.peoplesCard}>
                                <div>
                                    <Avatar picture={imagemLeonardo} name={'Leonardo'} size={70} />
                                    <div className={$.peoplesCardText}>
                                        &quot;Estou impressionado com a facilidade de uso do site e a rapidez com que recebi respostas precisas às minhas perguntas jurídicas. Recomendo a todos que buscam ajuda rápida e confiável em questões legais.&quot;
                                    </div>
                                </div>
                                <div>
                                    <div className={$.peoplesCardName}>Leonardo</div>
                                    <div className={$.peoplesCardRole}>Usuario</div>
                                </div>
                            </div>
                            <div className={$.peoplesCard}>
                                <div>
                                    <Avatar picture={imagemTiago} name={'Tiago'} size={70} />
                                    <div className={$.peoplesCardText}>
                                        &quot;A inteligência artificial é uma ótima opção para quem precisa de ajuda jurídica imediata. Eu usei a plataforma para fazer perguntas sobre questões fiscais e fiquei muito satisfeito com as respostas. A seção de busca por advogados também foi muito útil para encontrar um profissional competente em minha região.&quot;
                                    </div>
                                </div>
                                <div>
                                    <div className={$.peoplesCardName}>Tiago</div>
                                    <div className={$.peoplesCardRole}>Usuario</div>
                                </div>
                            </div>
                            <div className={$.peoplesCard}>
                                <div>
                                    <Avatar picture={imagemRafaella} name={'Rafaella'} size={70} />
                                    <div className={$.peoplesCardText}>
                                        &quot;Eu estava com muitas dúvidas sobre minha situação legal e não sabia por onde começar. Foi então que o Legify e fiquei surpreso com a qualidade do serviço. Recebi respostas rápidas e confiáveis e consegui encontrar um advogado próximo para me ajudar a resolver meu caso.&quot;
                                    </div>
                                </div>
                                <div>
                                    <div className={$.peoplesCardName}>Rafaella</div>
                                    <div className={$.peoplesCardRole}>Usuario</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={$.pricing} id="precos">
                        <div className={$.pricingTitle}>
                            Nossos <span className={$.presentationTitleHigh}>Planos</span>
                        </div>
                        <div className={$.pricingList}>
                            <div className={$.pricingCard}>
                                <img alt='Cliente 2' src={imagemCli2} className={$.pricingImage1} width='173' height='200' loading="eager" />
                                <div className={$.pricingPlan}>Para você que busca assessoria jurídica</div>
                                <div className={$.pricingSub}>Nossa plataforma de suporte jurídico oferece acesso a funcionalidades, como perguntas e respostas com nossa inteligência artificial e pesquisa de advogados qualificados.</div>
                                <Button text='Criar Conta' color='var(--primary_main)' textColor='var(--primary_main)' type='outline' stretch onClick={() => navigate('/login')} />
                                <div className={$.pricingPrice}>GRÁTIS</div>
                                <div className={$.pricingGet}>O que está incluso:</div>
                                <div className={$.pricingGetList}>
                                    <div className={$.pricingGetItem}>
                                        <FontAwesomeIcon icon={faQuestion} className={$.pricingGetIcon} />
                                        Tire suas duvidas com a maior inteligência artificial já criada.
                                    </div>
                                    <div className={$.pricingGetItem}>
                                        <FontAwesomeIcon icon={faMagnifyingGlass} className={$.pricingGetIcon} />
                                        Encontre os melhores advogados do Brasil.
                                    </div>
                                </div>
                            </div>

                            <div className={$.pricingCard}>
                                <img alt='Cliente 1' src={imagemCli1} className={$.pricingImage2} width='223' height='200' loading="eager" />
                                <div className={$.pricingPlan}>Recursos exclusivos para advogados</div>
                                <div className={$.pricingSub}>Faça parte do nosso grupo de parceiros e tenha acesso a uma lista de recursos exclusivos. Aproveite esta oportunidade única para fortalecer sua presença online no mercado jurídico.</div>
                                <Button text='Criar Conta' color='var(--primary_main)' textColor='var(--primary_main)' type='outline' stretch onClick={() => navigate('/login')} />
                                <div className={$.pricingPrice}>R$39.90/mês após o primeiro mês</div>
                                <div className={$.pricingGet}>O que está incluso:</div>
                                <div className={$.pricingGetList}>
                                    <div className={$.pricingGetItem}>
                                        <FontAwesomeIcon icon={faHandshakeAngle} className={$.pricingGetIcon} />
                                        O primeiro mes é gratuito.
                                    </div>
                                    <div className={$.pricingGetItem}>
                                        <FontAwesomeIcon icon={faHandshakeAngle} className={$.pricingGetIcon} />
                                        Tira duvidas com a inteligência artificial sobre diversos temas jurídicos.
                                    </div>
                                    <div className={$.pricingGetItem}>
                                        <FontAwesomeIcon icon={faUsers} className={$.pricingGetIcon} />
                                        Faça parte do nosso grupo de advogados parceiros.
                                    </div>
                                    <div className={$.pricingGetItem}>
                                        <FontAwesomeIcon icon={faBan} className={$.pricingGetIcon} />
                                        Cancele a qualquer momento.
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={$.footer}>
                        <div className={$.footerWrapper}>
                            <div className={$.footerRights}>
                                Desenvolvido por legify. Copyright © {new Date().getUTCFullYear()}. Todos os direitos reservados.
                            </div>
                            <div className={$.footerTerms}>
                                <div onClick={() => setPopup('termo')}>Termos de Serviço</div>
                                <div onClick={() => setPopup('privacidade')}>Privacidade</div>
                                <div onClick={() => setPopup('seguranca')}>Segurança</div>
                            </div>
                        </div>
                    </div>
                    {
                        popup &&
                        <Dialog closeButton={true} onBlur={() => setPopup()}>
                            {popup === 'termo' && <TermoServico />}
                            {popup === 'privacidade' && <Privacidade />}
                            {popup === 'seguranca' && <Seguranca />}
                        </Dialog>
                    }
                </div>
            </div>
        </>
    )
}
