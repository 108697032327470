import { React } from 'react';
import { useEffect, useRef } from 'react';
import $ from './Dialog.module.css';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Badge from '../Badge/Badge';

export default function Dialog(p) {

    const { children, onBlur, closeButton = false } = p;

    const ref = useRef(null);

    useEffect(() => {
        const handleClickOutside = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                onBlur && onBlur();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <div className={$.block} ref={ref}>
                    {closeButton && <span className={$.close} onClick={() => onBlur()}><Badge icon={faXmark} color={'var(--texto2)'} /></span>}
                    {children}
                </div>
            </div>
        </div>
    )
}
