import Avatar from './../../Components/Avatar/Avatar';
import Badge from './../../Components/Badge/Badge';
import $ from './Card.module.css'
import { faFacebook, faInstagram, faLinkedin, faTwitter, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faLocationDot, faMapLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { genderRole } from '../../Utils/Professional';
import React from 'react';


export default function Card(params) {

    const { pro, color } = params;

    const clearNumber = () => {
        return pro.contact.replace(/\D+/g, '');
    }

    return (
        <div className={$.container} style={{ backgroundColor: color ? color : 'var(--destaque)' }}>
            <div className={$.wrapper}>
                <div className={$.header} style={{ backgroundImage: 'url(' + process.env.REACT_APP_FILE_SERVER + pro.cover + ')' }}>
                    <div className={$.layer}>
                        <div className={$.photo}>
                            <Avatar name={pro.name} picture={process.env.REACT_APP_FILE_SERVER + pro.picture} size={60} />
                            <div className={$.nomeEmail}>
                                <div className={$.name}>
                                    <div className={$.value}>
                                        {pro.name}
                                    </div>
                                    <div className={$.label}>
                                        {genderRole(pro?.sex)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={$.body}>
                    <div className={$.email}>
                        <div className={$.label}><FontAwesomeIcon icon={faEnvelope} />E-mail</div>
                        <div className={$.value}>
                            {pro.email}
                        </div>
                    </div>
                    <div className={$.distanceScore}>
                        <div className={$.distance}>
                            <div className={$.label}><FontAwesomeIcon icon={faMapLocationDot} />Distância</div>
                            <div className={$.value}>
                                {pro.distance !== undefined ? '± ' + pro.distance + ' km' : '-'}
                            </div>
                        </div>
                        <div className={$.location}>
                            <div className={$.label}><FontAwesomeIcon icon={faLocationDot} />Localização</div>
                            <div className={$.value}>
                                {pro.neighborhood || 'Brasil'}
                            </div>
                        </div>
                        {/* <div className={$.score}>
                            <div className={$.label}><FontAwesomeIcon icon={faMedal} />Avaliação</div>
                            <div className={$.starList}>
                                <FontAwesomeIcon icon={faStar} className={calculateScore(pro?.scores) >= 1 ? $.starActive : $.star} />
                                <FontAwesomeIcon icon={faStar} className={calculateScore(pro?.scores) >= 2 ? $.starActive : $.star} />
                                <FontAwesomeIcon icon={faStar} className={calculateScore(pro?.scores) >= 3 ? $.starActive : $.star} />
                                <FontAwesomeIcon icon={faStar} className={calculateScore(pro?.scores) >= 4 ? $.starActive : $.star} />
                                <FontAwesomeIcon icon={faStar} className={calculateScore(pro?.scores) >= 5 ? $.starActive : $.star} />
                            </div>
                        </div> */}
                    </div>
                    <div className={$.phoneLocation}>
                        <div className={$.phone}>
                            <div className={$.label}><FontAwesomeIcon icon={faPhone} />Contato</div>
                            <div className={$.value}>
                                {pro.contact || '-'}
                            </div>
                        </div>

                    </div>
                    <div className={$.social}>
                        <div className={$.actions}>
                            {pro.contact && <a href={'https://wa.me/' + clearNumber() + '?text=Encontrei seu contato no Legify, eu tenho uma duvida.'} rel='noreferrer' target='_blank'><Badge icon={faWhatsapp} color='#25D366' /></a>}
                            {pro?.social?.facebook && <a href={pro.social.facebook} rel='noreferrer' target='_blank'><Badge icon={faFacebook} color='#4267B2' /></a>}
                            {pro?.social?.instagram && <a href={pro.social.instagram} rel='noreferrer' target='_blank'><Badge icon={faInstagram} color='#C13584' /></a>}
                            {pro?.social?.linkedin && <a href={pro.social.linkedin} rel='noreferrer' target='_blank'><Badge icon={faLinkedin} color='#0e76a8 ' /></a>}
                            {pro?.social?.twitter && <a href={pro.social.twitter} rel='noreferrer' target='_blank'><Badge icon={faTwitter} color='#1DA1F2' /></a>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}