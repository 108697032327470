import { React, useEffect, useContext, useState } from 'react';
import { ProsContext } from './../../Context/ProsContext';
import Card from './Card';
import $ from './Professionals.module.css'
import Dialog from '../../Components/Dialog/Dialog';
import Viewer from './Viewer';
import TextField from '../../Components/TextField/TextField';
import diacriticless from 'diacriticless';
import DropDown from '../../Components/DropDown/DropDown';
import { states } from '../../Utils/Address';
import { sexs } from '../../Utils/Professional';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { getDistanceBetweenLocations } from '../../Services/Maps';
import { UserContext } from '../../Context/UserContext';
import { askForLocation } from '../../Utils/Maps';
import { getSubscription } from '../../Services/Stripe';
import Filter from './Filter';
import { searchUsers } from '../../Services/Database/User';
import { Helmet } from 'react-helmet';

export default function Professionals() {

    const { pros, setPros } = useContext(ProsContext);
    const { user, setUser } = useContext(UserContext);
    const [proList, setProList] = useState();
    const [expand, setExpand] = useState();
    const [expandOrder, setExpandOrder] = useState(false);
    const [expandInfos, setExpandInfos] = useState(false);
    const [expandLocations, setExpandLocations] = useState(false);
    const [expandSpec, setExpandSpec] = useState(false);
    const [filterOrder, setFilterOrder] = useState('MAIS_PROXIMOS');
    const [filterSex, setFilterSex] = useState();
    const [filterCEP, setFilterCEP] = useState();
    const [filterAddress, setFilterAddress] = useState();
    const [filterNei, setFilterNei] = useState();
    const [filterCity, setFilterCity] = useState();
    const [filterArea, setFilterArea] = useState();
    const [filterState, setFilterState] = useState();

    useEffect(() => {
        const getUserLocation = async () => {
            const location = await askForLocation();
            if (location && location.lat && location.lon) {
                setUser(prevUser => ({ ...prevUser, lat: location.lat, lon: location.lon }));
            }
        };
        getUserLocation();
        load();
    }, []);

    const load = () => {
        searchUsers({ role: 'Advogado', validated: true }).then((data) => {
            if (data) {
                var newList = [];
                data = data.length ? data : [data];
                data.forEach((item) => {
                    var isPremium = false;
                    if (item.customerId && item.validated) {
                        isPremium = getSubscription(item.customerId)
                        var dis = getDistanceBetweenLocations({ lat: item.lat, lon: item.lon }, { lat: user.lat, lon: user.lon });
                        item.isPremium = isPremium;
                        item.distance = (dis * 1.3).toFixed(2)
                        item.distance = item.distance >= 0 ? item.distance : (0).toFixed(2);
                        item.picture = item.picture ? item.picture : 'user.png';
                        item.cover = item.cover ? item.cover : 'cover.jpg';
                        newList.push(item);
                    }
                })
                setPros(newList);
            }
        });
    }

    const filterBySex = (pros) =>
        filterSex
            ? pros.filter(
                (x) =>
                    x.sex &&
                    diacriticless(x.sex.toLowerCase()).includes(
                        diacriticless(filterSex.toLowerCase())
                    )
            )
            : pros;

    const filterByCEP = (pros) =>
        filterCEP
            ? pros.filter(
                (x) =>
                    x.cep &&
                    diacriticless(x.cep.toLowerCase()).includes(
                        diacriticless(filterCEP.toLowerCase())
                    )
            )
            : pros;

    const filterByAddress = (pros) =>
        filterAddress
            ? pros.filter(
                (x) =>
                    x.address &&
                    diacriticless(x.address.toLowerCase()).includes(
                        diacriticless(filterAddress.toLowerCase())
                    )
            )
            : pros;

    const filterByNeighborhood = (pros) =>
        filterNei
            ? pros.filter(
                (x) =>
                    x.neighborhood &&
                    diacriticless(x.neighborhood.toLowerCase()).includes(
                        diacriticless(filterNei.toLowerCase())
                    )
            )
            : pros;

    const filterByCity = (pros) =>
        filterCity
            ? pros.filter(
                (x) =>
                    x.city &&
                    diacriticless(x.city.toLowerCase()).includes(
                        diacriticless(filterCity.toLowerCase())
                    )
            )
            : pros;

    const filterByState = (pros) =>
        filterState
            ? pros.filter(
                (x) =>
                    x.state &&
                    diacriticless(x.state.toLowerCase()).includes(
                        diacriticless(filterState.toLowerCase())
                    )
            )
            : pros;

                        console.log(pros)

    const filterByArea = (pros) =>
        filterArea
            ? pros.filter(
                (x) =>
                    x.areas && x.areas.some((y) => y.area === filterArea)
            )
            : pros;    

    const order = (pros) => {

        if (!pros)
            return;

        return pros?.sort(function (a, b) {

            if (filterOrder === 'MAIS_ACESSADOS')
                return b.views - a.views;

            if (filterOrder === 'MENOS_ACESSADOS')
                return a.views - b.views;

            if (filterOrder === 'MAIS_PROXIMOS')
                return parseFloat(a.distance || 9999) - parseFloat(b.distance || 9999);

            if (filterOrder === 'MENOS_PROXIMOS') {
                return parseFloat(b.distance || 9999) - parseFloat(a.distance || 9999);
            }

            if (filterOrder === 'NOME')
                return a.name.localeCompare(b.name);

            return parseFloat(a.distance || 9999) - parseFloat(b.distance || 9999);
        });
    }

    const updateDistance = (key, distance) => {

        var updated = proList;

        updated.forEach(element => {
            if (element.id === key)
                element.distance = distance
        });

        setProList(updated);
    }

    const clearFilters = () => {
        setFilterOrder('MAIS_PROXIMOS');
        setFilterSex('');
        setFilterCEP('');
        setFilterAddress('');
        setFilterNei('');
        setFilterCity('');
        setFilterArea('');
        setFilterState('');
    }

    const showFilter = (name) => {
        setExpandOrder(false)
        setExpandInfos(false)
        setExpandLocations(false)
        setExpandSpec(false)

        if (name === 'order')
            setExpandOrder(!expandOrder);
        if (name === 'info')
            setExpandInfos(!expandInfos);
        if (name === 'location')
            setExpandLocations(!expandLocations);
        if (name === 'spec')
            setExpandSpec(!expandSpec);
    }

    useEffect(() => {
        var list = order(filterByState(filterByCity(filterByNeighborhood(filterByAddress(filterByCEP(filterBySex(filterByArea(pros))))))));
        setProList([...list.filter(x => x.isPremium)]);
    }, [pros, filterSex, filterCEP, filterAddress, filterNei, filterCity, filterArea, filterState, filterOrder])

    return (
        <>
            <Helmet>
                <title>Legify | Pesquisa de Profissionais</title>
            </Helmet>
            <div className={$.container}>
                <div className={$.filtersHeader}>
                    <div className={$.filterTitle}>
                        <div>
                            FILTROS
                        </div>
                        <div onClick={() => clearFilters()} style={{ cursor: 'pointer' }}>
                            LIMPAR FILTROS
                        </div>
                    </div>
                    {
                        <div className={$.filters}>
                            <div className={$.filtersBlock}>
                                <div className={$.filterBlockTitle} onClick={() => showFilter('info')}>
                                    INFORMAÇÕES PESSOAIS
                                    <FontAwesomeIcon icon={!expandInfos ? faChevronDown : faChevronUp} />
                                </div>
                                {
                                    expandInfos &&
                                    <div className={$.filterBlockList}>
                                        <DropDown title='Sexo' get={filterSex} set={setFilterSex} list={sexs} />
                                    </div>
                                }
                            </div>
                            <div className={$.filtersBlock}>
                                <div className={$.filterBlockTitle} onClick={() => showFilter('location')}>
                                    LOCALIZAÇÃO
                                    <FontAwesomeIcon icon={!expandLocations ? faChevronDown : faChevronUp} />
                                </div>
                                {
                                    expandLocations &&
                                    <div className={$.filterBlockList}>
                                        <TextField title={'CEP'} get={filterCEP} set={setFilterCEP} format='cep' autocomplete='new-password' />
                                        <TextField title={'Endereço'} get={filterAddress} set={setFilterAddress} autocomplete='new-password' />
                                        <TextField title={'Bairro'} get={filterNei} set={setFilterNei} autocomplete='new-password' />
                                        <TextField title={'Cidade'} get={filterCity} set={setFilterCity} autocomplete='new-password' />
                                        <DropDown title='Estado' get={filterState} set={setFilterState} list={states} />
                                    </div>
                                }
                            </div>
                            <Filter title={'ESPECIALIZAÇÃO'} field={'areas'} onChange={setFilterArea} />
                            <Filter title={'ORDENAÇÃO'} field={'orders'} onChange={setFilterOrder} />
                        </div>
                    }
                </div>
                <div className={`${$.wrapper} ${$.slideIn}`} onClick={() => showFilter()}>
                    {
                        proList?.map((x) =>
                            <div className={$.cards} key={x.id} onClick={() => setExpand(x)} >
                                <Card key={x.id} pro={x} updateDistance={updateDistance} />
                            </div>
                        )
                    }
                </div>
                {expand && <Dialog onBlur={() => setExpand()} closeButton><Viewer professional={expand} /></Dialog>}
            </div>
        </>
    )
}
