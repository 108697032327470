import { React } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faInfoCircle, faWarning, faXmark } from '@fortawesome/free-solid-svg-icons';
import $ from './Alert.module.css';
import { useEffect } from 'react';
import { useState } from 'react';

export default function Alert(p) {

    const { id, type, text, onClose } = p;

    const [close, setClose] = useState(false);

    const icon = () => {
        if (type === 'info') return faInfoCircle;
        if (type === 'success') return faCheckCircle;
        if (type === 'warning') return faWarning;
        if (type === 'error') return faExclamationCircle;
    }

    const color = () => {
        if (type === 'info') return '#00b8d9';
        if (type === 'success') return '#36b37e';
        if (type === 'warning') return '#ffab00';
        if (type === 'error') return '#ff5630';
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setClose(true)
        }, 4000);

        const destroy = setTimeout(() => {
            onClose(id)
        }, 4300);

        return () => {
            clearTimeout(timer);
            clearTimeout(destroy);
        };
    }, []);


    return (
        <div className={$.container} id={'alert'}>
            <div className={$.wrapper} style={{
                backgroundColor: color(),
                transform: close ? 'translateX(calc(100% + 35px))' : 'translateX(0)'
            }}>
                <div className={$.information}>
                    <FontAwesomeIcon className={$.informationIcon} icon={icon()} />
                    {text}
                </div>
                <FontAwesomeIcon icon={faXmark} className={$.close} onClick={() => onClose(id)} />
            </div>
        </div >
    )
}