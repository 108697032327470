import Header from '../_components/Header';
import $ from './Dashboard.module.css';
import { UserContext } from '../../Context/UserContext';
import { React, useContext, useEffect, useState } from 'react';
import { getArea } from './../../Utils/Professional';
import Number from './Number';
import Pie from './Pie';
import { searchAreas } from '../../Services/Database/Area';
import { tableRows } from '../../Services/Database/Table';
import { Helmet } from 'react-helmet';

export default function Dashboard() {

    const { user } = useContext(UserContext);
    const [areas, setAreas] = useState();
    const [totalUsers, setTotalUsers] = useState(0);
    const [totalQuestions, setTotalQuestions] = useState(0);

    useEffect(() => {
        searchAreas().then((result) => {
            if (result) {
                var newList = [];
                result.forEach((item) => {
                    newList.push({ key: getArea(item.name), value: item.total })
                })
                setAreas(newList);
            }
        })

        tableRows({ table: 'User' }).then(result => {
            setTotalUsers(result.count);
        })

        tableRows({ table: 'Question' }).then(result => {
            setTotalQuestions(result.count);
        })

    }, [])

    return (
        <>
            <Helmet>
                <title>Legify | Dashboard</title>
            </Helmet>
            <div className={$.container}>
                <div className={$.wrapper}>
                    <Header
                        title={'Olá ' + user.name.split(' ')[0]} />
                    <div className={$.dashboards}>
                        <div className={$.dash1}>
                            <Number title={'Visualizações de Perfil'} value={user.views ? user.views : 0} />
                        </div>
                        <div className={$.dash1}>
                            <Number title={'Total de Usuários'} value={totalUsers} />
                        </div>
                        <div className={$.dash1}>
                            <Number title={'Respostas Dadas pela Int. Artificial'} value={totalQuestions} />
                        </div>
                    </div>
                    <div className={$.dashboards}>
                        <div className={$.dash1}>
                            <Pie title={'Áreas Mais Procuradas'} values={areas} />
                        </div>
                        <div className={$.dash1}></div>
                        <div className={$.dash1}></div>
                        {/* <div className={$.dash2}>
                        <Lines title={'Interações com a Inteligância Artificial'} />
                    </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}


//Quadro com numero
//Numero de usuarios registrados na plataforma
//Numero de usuarios a 10km de vc
//Clicks no perfil do advogado


//Tempo medio de sessao

//Numero de interações com a IA

//Area mais procurada
