import { React } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from './Button.module.css';

export default function Button(p) {

    const { id, text, icon, color = 'transparent', textColor = '#FFFFFF', type = 'painted', onClick, size = 'medium', enabled = true, children, stretch = false } = p;

    const padding = () => {
        if (size === 'small') return '6px';
        if (size === 'medium') return '10px';
        if (size === 'large') return '14px';
    }

    const iconSize = () => {
        if (size === 'small') return '12px';
        if (size === 'medium') return '16px';
        if (size === 'large') return '20px';
    }

    const style = () => {
        if (type === 'painted') {
            return {
                width: stretch ? '100%' : 'auto',
                border: '1px solid ' + (enabled ? color : '#383f49'),
                backgroundColor: enabled ? color : '#383f49',
                color: enabled ? textColor : '#6c7783',
                paddingTop: padding(),
                paddingBottom: padding(),
                cursor: enabled ? 'pointer' : 'auto'
            }
        }
        if (type === 'outline') {
            return {
                width: stretch ? '100%' : 'auto',
                border: '1px solid ' + (enabled ? color : '#383f49'),
                color: enabled ? textColor : '#6c7783',
                paddingTop: padding(),
                paddingBottom: padding(),
                cursor: enabled ? 'pointer' : 'auto'
            }
        }
        if (type === 'text') {
            return {
                width: stretch ? '100%' : 'auto',
                color: enabled ? textColor : '#6c7783',
                paddingTop: padding(),
                paddingBottom: padding(),
                cursor: enabled ? 'pointer' : 'auto'
            }
        }
        if (type === 'icon')
            return {
                width: stretch ? '100%' : 'auto',
                color: enabled ? textColor : '#6c7783',
                paddingTop: padding(),
                paddingBottom: padding(),
                cursor: enabled ? 'pointer' : 'auto',
                fontSize: iconSize()
            }
    }

    return (
        <button id={id ? id : text} className={$.container} style={style()} onClick={() => enabled && onClick && onClick()}>
            {icon && <FontAwesomeIcon icon={icon} />}
            {text && <div>{text}</div>}
            {children}
        </button>
    )
}