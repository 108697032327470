import React from 'react';
import $ from './LoadingPage.module.css'
import Logo from '../../Images/logo_rocket.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';

export default function LoadingPage(params) {

    const { error } = params;
    const imagemLogo = useMemo(() => Logo, []);

    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <img alt='Legify Logo' src={imagemLogo} className={$.logo} width='60' height='60'/>
                <div className={$.text}>
                    Estamos preparando seu perfil..
                </div>
                {
                    error ? <div className={$.error}>
                        <FontAwesomeIcon icon={faTriangleExclamation} className={$.alert} />
                        Ocorreu um problema ao carregar suas informações, atualize a pagina e tente novamente
                    </div>
                        :
                        <FontAwesomeIcon icon={faSpinner} className={$.icon} />
                }
            </div>
        </div >
    )
}