import $ from './Contact.module.css'
import Header from '../_components/Header';
import Infos from './Infos';
import { Helmet } from 'react-helmet';
import React from 'react';


export default function Contact() {
    return (
        <>
            <Helmet>
                <title>Legify | Contato</title>
            </Helmet>
            <div className={$.container}>
                <div className={$.wrapper}>
                    <Header
                        title={'Contato'} />
                    <Infos title={'FALE CONOSCO'} />
                </div>
            </div>
        </>
    )
}