import { React, useState } from 'react';
import Avatar from '../../Components/Avatar/Avatar';
import $ from './Viewer.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEarthAmericas, faEnvelope, faLocationDot, faMapLocationDot, faPhone, faVenusMars } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faLinkedin, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Badge from '../../Components/Badge/Badge';
import { useEffect } from 'react';
import { genderRole, getArea, getSex } from '../../Utils/Professional';
import { saveUser, searchUsers } from '../../Services/Database/User';

export default function Viewer(params) {

    const { professional, updateHandler } = params;
    //const [star, setStar] = useState();
    const [views, setViews] = useState('-')
    // var totalScore = 0;

    const clearNumber = () => {
        return professional.contact.replace(/\D+/g, '');
    }

    // const starHandler = (v) => {
    //     if (star === v) {
    //         setStar(0)
    //         v = 0;
    //     }
    //     else
    //         setStar(v)

    //     updateScore(v);
    // }

    const updateView = () => {
        searchUsers({ email: professional.email }).then((result) => {
            if (result.length > 0) {
                var updatedProfessional = result[0];
                updatedProfessional.views = (updatedProfessional.views ? updatedProfessional.views : 0) + 1;
                setViews(updatedProfessional.views);
                saveUser(updatedProfessional)
                updateHandler();
            }
        })
    }

    // const updateScore = (v) => {
    //     searchUsers({ email: professional.email }).then((updatedProfessional) => {
    //         var scores = updatedProfessional.scores ?? []
    //         var curScore = { ref: user.id, score: v }
    //         if (updatedProfessional.scores?.some(x => x.ref === user.id)) {
    //             scores.forEach(element => {
    //                 if (element.ref === user.id)
    //                     element.score = v;
    //             });
    //         }
    //         else
    //             scores.push(curScore)
    //         saveUsers('users', professional.id, { scores: scores })
    //         updateHandler();
    //     })
    // }

    const validadeLicense = () => {
        return true;
    }

    useEffect(() => {
        // var curScore = professional.scores?.filter(x => x.ref === user.id);
        // if (curScore && curScore.length > 0) {
        //     setStar(curScore[0].score);
        // }
        // else
        //     setStar(0);

        updateView();

    }, [])

    // totalScore = calculateScore(professional.scores);

    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <div className={$.header}>
                    <div className={$.headerLeft}>
                        <Avatar name={professional.name} picture={process.env.REACT_APP_FILE_SERVER + professional.picture} size={120} />
                        <div className={$.headerInfos}>
                            <div className={$.headerName}>
                                {professional.name}
                                {validadeLicense() && <Badge icon={faCheckCircle} color={'var(--green)'} />}
                            </div>
                            <div className={$.headerRole}>{genderRole(professional.sex)}</div>
                        </div>
                    </div>
                    {/* <div className={$.headerRight}>
                        <div className={$.aval}>Já é cliente? Avalie o {firstName()}</div>
                        <div className={$.starGroup}>
                            <FontAwesomeIcon icon={faStar} className={star >= 5 ? $.starActive : $.star} onClick={() => starHandler(5)} />
                            <FontAwesomeIcon icon={faStar} className={star >= 4 ? $.starActive : $.star} onClick={() => starHandler(4)} />
                            <FontAwesomeIcon icon={faStar} className={star >= 3 ? $.starActive : $.star} onClick={() => starHandler(3)} />
                            <FontAwesomeIcon icon={faStar} className={star >= 2 ? $.starActive : $.star} onClick={() => starHandler(2)} />
                            <FontAwesomeIcon icon={faStar} className={star >= 1 ? $.starActive : $.star} onClick={() => starHandler(1)} />
                        </div>
                    </div> */}
                </div>
                <div className={$.content}>
                    <div className={$.leftPanel}>
                        <div className={$.statics}>
                            <div className={$.staticsInfo}>
                                <div className={$.staticsValue}>{views}</div>
                                <div className={$.staticsLabel}>Visualizações do Perfil</div>
                            </div>
                            {/* <div className={$.line} />
                            <div className={$.staticsInfo}>
                                {totalScore > 0 ? <div className={$.staticsValue}>{totalScore}<FontAwesomeIcon icon={faStar} className={$.starActive} /></div> : <div className={$.staticsValue}>-</div>}
                                <div className={$.staticsLabel}>Avaliação</div>
                            </div> */}
                        </div>
                        <div className={$.infos}>
                            <div className={$.infosTitle}>Sobre</div>
                            <div className={$.infosContent}>
                                {professional.sex && <div className={$.infosValue}><FontAwesomeIcon icon={faVenusMars} className={$.infosIcon} />Gênero <b>{getSex(professional.sex)}</b></div>}
                                <div className={$.infosValue}><FontAwesomeIcon icon={faMapLocationDot} className={$.infosIcon} />A <b>{professional.distance} km</b> de distancia de você</div>
                                {professional.distance && <div className={$.infosValue}><FontAwesomeIcon icon={faEarthAmericas} className={$.infosIcon} />Atende em <b>{professional.city}{professional.neighborhood && '/' + professional.neighborhood}</b></div>}
                                <div className={$.infosValue}><FontAwesomeIcon icon={faLocationDot} className={$.infosIcon} />Endereço <b><a href={'https://www.google.com/maps/search/?api=1&query=' + professional.address + ', ' + professional.city} target={'_blank'} rel='noreferrer'>{professional.address}</a></b></div>
                                <div className={$.infosValue}><FontAwesomeIcon icon={faEnvelope} className={$.infosIcon} />{professional.email}</div>
                                <div className={$.infosValue}><FontAwesomeIcon icon={faPhone} className={$.infosIcon} />{professional.contact}</div>
                            </div>
                        </div>
                        <div className={$.infos}>
                            <div className={$.infosTitle}>Redes Sociais</div>
                            <div className={$.infosContentSocial}>
                                {professional?.contact && <div className={$.infosValue}><a href={'https://wa.me/' + clearNumber() + '?text=Encontrei seu contato no Legify, eu tenho uma duvida.'} rel='noreferrer' target='_blank'><Badge icon={faWhatsapp} color='#25D366' /></a></div>}
                                {professional?.social?.facebook && <div className={$.infosValue}><a href={professional.social.facebook} rel='noreferrer' target='_blank'><Badge icon={faFacebook} color='#4267B2' /></a></div>}
                                {professional?.social?.instagram && <div className={$.infosValue}><a href={professional.social.instagram} rel='noreferrer' target='_blank'><Badge icon={faInstagram} color='#C13584' /></a></div>}
                                {professional?.social?.linkedin && <div className={$.infosValue}><a href={professional.social.linkedin} rel='noreferrer' target='_blank'><Badge icon={faLinkedin} color='#0e76a8 ' /></a></div>}
                                {professional?.social?.twitter && <div className={$.infosValue}><a href={professional.social.twitter} rel='noreferrer' target='_blank'><Badge icon={faTwitter} color='#1DA1F2' /></a></div>}
                            </div>
                        </div>
                    </div>
                    <div className={$.rightPanel}>
                        <div className={$.infos}>
                            <div className={$.infosTitle}>Sobre o Profissional</div>
                            <div className={$.infosContent}>
                                <div className={$.infosValue}>{professional?.about}</div>
                            </div>
                        </div>
                        {
                            professional.areas &&
                            <div className={$.infos}>
                                <div className={$.infosTitle}>Áreas de Atuação</div>
                                <div className={$.infosContent}>
                                    {
                                        professional.areas?.map(item => <div key={item.area} className={$.infosValue}>{getArea(item.area)}</div>)
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div >
    )

}