import { calculateDistance } from "./../Utils/Maps";

export const getWorldLocation = async (street, city) => {

    if (street === '' || city === '')
        return null;

    street = 'street=' + street;
    city = '&city=' + city;

    const response = await fetch(`https://nominatim.openstreetmap.org/search?${street}${city}&country=br&format=json&countrycodes=br`);

    const data = await response.json();
    if (!data || data.length === 0)
        return

    return {
        lat: parseFloat(data[0].lat), lon: parseFloat(data[0].lon)
    };
}

export const getDistanceBetweenLocations = (location1, location2) => {

    if (!location1 || !location2)
        return -1;

    return calculateDistance(parseFloat(location1.lat), parseFloat(location1.lon), parseFloat(location2.lat), parseFloat(location2.lon));
}
