import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import $ from './StripeCheckout.module.css';

import CheckoutForm from "./StripeForm";
import { getSubscription } from "../../Services/Stripe";
import { useContext } from "react";
import { UserContext } from "../../Context/UserContext";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function StripeCheckout(params) {

    const { onSuccess } = params;
    const { user } = useContext(UserContext);
    const [subscription, setSubscription] = useState();

    const close = () => {
        setSubscription();
        onSuccess();
    }

    useEffect(() => {
        getSubscription(user.customerId).then((sub) => {
            setSubscription(sub);
        })
    }, [])

    return (
        <div className={$.container} >
            {
                subscription && subscription.clientSecret &&
                <div>
                    <div className={$.hint}>
                        Parece que você não informou uma forma de pagamento para a renovação, caso não seja informada seu plano sera cancelado automaticamente após o vencimento do plano.

                    </div>
                    <Elements options={{ clientSecret: subscription.clientSecret, theme: 'stripe' }} stripe={stripePromise}>
                        <CheckoutForm clientSecret={subscription.clientSecret} subscription={user.subscription} onSuccess={close} intent={subscription.status === 'incomplete' ? 'payment' : 'setup'} />
                    </Elements>
                </div>
            }
        </div>
    );
}