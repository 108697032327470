import { React, useState, useContext } from 'react';
import $ from './SignOn.module.css';
import TextField from '../../Components/TextField/TextField';
import Button from '../../Components/Button/Button';
import Badge from '../../Components/Badge/Badge';
import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from 'react-router-dom'
import { deleteAccount, signInWithEmailAndPassword, signInWithFacebook, signInWithGoogle } from '../../Firebase';
import { v4 as uuidv4 } from 'uuid';
import { AlertContext } from '../../Context/AlertContext';
import { SEM_CONTA_FACEBOOK, SEM_CONTA_GOOGLE, USUARIO_SENHA_INCORRETOS } from '../../Messages/Login';

export default function SignOn(params) {

    const { handleClick } = params;

    const navigate = useNavigate();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const { alerts, setAlerts } = useContext(AlertContext);

    const showAlert = (type, message) => {
        const id = uuidv4();
        const newAlert = { id, message, type };
        setAlerts([...alerts, newAlert]);
    };

    const login = () => {
        signInWithEmailAndPassword(email, password).then(() => {
            navigate('/professionals')
        }).catch(() => {
            showAlert('error', USUARIO_SENHA_INCORRETOS);
        })
    }

    const google = () => {
        signInWithGoogle().then((userCredential) => {
            if (userCredential.additionalUserInfo.isNewUser) {
                showAlert('warning', SEM_CONTA_GOOGLE);
                deleteAccount();
            }
            else {
                navigate('/professionals')
            }
        }).catch((error) => {
            if (error)
                showAlert('warning', error);
        });
    }

    const facebook = () => {
        signInWithFacebook().then((userCredential) => {
            if (userCredential.additionalUserInfo.isNewUser) {
                showAlert('warning', SEM_CONTA_FACEBOOK);
                deleteAccount();
            }
            else {
                navigate('/professionals')
            }
        }).catch((error) => {
            if (error)
                showAlert('warning', error);
        });
    }

    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <div className={$.block}>
                    <div className={$.title}>Entrar no Legify</div>
                    <div className={$.new}>É novo aqui? <span className={$.newLink} onClick={() => handleClick('presignin')} id='criar_conta'>Criar uma conta</span></div>
                    <div className={$.field}>
                        <TextField title='E-mail' get={email} set={setEmail} autofocus />
                    </div>
                    <div className={$.field}>
                        <TextField title='Senha' type='password' get={password} set={setPassword} />
                    </div>
                    <div className={$.forgot} onClick={() => handleClick('forgot')} id={'recuperar_senha'}>Esqueceu a senha?</div>
                    <div className={$.button}>
                        <Button text='Login' color='var(--texto1)' textColor='var(--background)' size='large' stretch onClick={() => login()} />
                    </div>
                    <div className={$.or}>
                        <span className={$.line}>OU</span>
                    </div>
                    <div className={$.social}>
                        <Badge icon={faFacebook} color={'#078dee'} onClick={() => facebook()} id={'facebook'} />
                        <Badge icon={faGoogle} color={'#df3e30'} onClick={() => google()} id={'google'} />
                        {/* <Badge icon={faTwitter} color={'#1c9cea'} /> */}
                    </div>
                </div>
            </div>
        </div>)
}
