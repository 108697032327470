export const askForLocation = () => {
    return new Promise((resolve) => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                var latitude = position.coords.latitude;
                var longitude = position.coords.longitude;
                resolve({ lat: latitude, lon: longitude });
            })
        }
    })
};

export const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const raioTerraKm = 6371;
    const latRad1 = degToRad(lat1);
    const lonRad1 = degToRad(lon1);
    const latRad2 = degToRad(lat2);
    const lonRad2 = degToRad(lon2);
    const dLat = latRad2 - latRad1;
    const dLon = lonRad2 - lonRad1;
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(latRad1) * Math.cos(latRad2) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distanciaKm = raioTerraKm * c;
    return distanciaKm;
}

const degToRad = (graus) => {
    return graus * (Math.PI / 180);
}