export const areas = [
    { key: "DIREITO_CIVIL", value: "Direito Civil" },
    { key: "DIREITO_CRIMINAL", value: "Direito Criminal" },
    { key: "DIREITO_DO_TRABALHO", value: "Direito do Trabalho" },
    { key: "DIREITO_TRIBUTARIO", value: "Direito Tributário" },
    { key: "DIREITO_EMPRESARIAL", value: "Direito Empresarial" },
    { key: "DIREITO_IMOBILIARIO", value: "Direito Imobiliário" },
    { key: "DIREITO_ADMINISTRATIVO", value: "Direito Administrativo" },
    { key: "DIREITO_CONSTITUCIONAL", value: "Direito Constitucional" },
    { key: "DIREITO_AMBIENTAL", value: "Direito Ambiental" },
    { key: "DIREITO_DO_CONSUMIDOR", value: "Direito do Consumidor" },
    { key: "DIREITO_INTERNACIONAL", value: "Direito Internacional" },
    { key: "DIREITO_FAMILIA", value: "Direito da Familia" },
    { key: "DIREITO_SAUDE", value: "Direito da Saúde" },
    { key: "DIREITO_ANIMAL", value: "Direito Animal" },
    { key: "DIREITO_PRIVACIDADE", value: "Direito da Privacidade" },
    { key: "DIREITO_TECNOLOGIA", value: "Direito da Tecnologia" },
];

export const getArea = (area) => {
    const result = areas.find(s => s.key === area);
    if (result) {
        return result.value;
    } else {
        return null;
    }
}

export const sexs = [
    { key: 'M', value: 'Masculino' },
    { key: 'F', value: 'Feminino' },
    { key: 'NB', value: 'Não-binário' },
    { key: 'T', value: 'Transgênero' },
    { key: 'GF', value: 'Gênero fluido' },
    { key: 'O', value: 'Outro' },
];

export const getSex = (sex) => {
    const result = sexs.find(s => s.key === sex);
    if (result) {
        return result.value;
    } else {
        return null;
    }
}

export const orders = [
    { key: 'MAIS_PROXIMOS', value: 'Mais próximos' },
    { key: 'MENOS_PROXIMOS', value: 'Mais distantes' },
    { key: 'MAIS_ACESSADOS', value: 'Mais acessados' },
    { key: 'MENOS_ACESSADOS', value: 'Menos acessados' },
    { key: 'NOME', value: 'Nome' },
]


export const formatCPF = (cpf) => {
    cpf = cpf.slice(0, 14);
    return cpf.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

export const formatOAB = (cpf) => {
    cpf = cpf.slice(0, 9);
    return cpf.replace(/\D/g, '')//.replace(/^(\d{2})(\d{6})(\d)$/, '$1.$2-$3');
};

export const genderRole = (gender) => {
    if (gender === 'M')
        return 'Advogado';
    if (gender === 'F')
        return 'Advogada';
    if (gender === 'NB' || gender === 'GF' || gender === 'AG')
        return 'Advogade'
    return 'Advogado'
}

export const calculateScore = (list) => {
    var scores = list ?? []
    var total = 0;
    var base = 0;
    if (scores.length > 0)
        scores.forEach(element => {
            if (element.score > 0) {
                total = total + element.score;
                base = base + 1;
            }
        })
    else
        return 0;
    return (total / base).toFixed(2)
}

export const canAccess = (role) => {
    return role === 'Advogado' || role === 'Administrador';
}