export const validadePassword = (pass) => {
    const regexCaracterEspecial = /[!@#$%^&*(),.?":{}|<>]/;
    const regexCaracter = /[a-zA-Z]/;
    const regexNumerico = /\d/;

    if (!pass)
        return 'Senha precisa ser informada.';

    if (pass.length < 8)
        return 'A senha precisa conter pelo menos 8 caracteres';

    if (!regexCaracter.test(pass))
        return 'A senha precisa conter pelo menos um caractere maiúsculo e/ou minúsculo.';

    if (!regexNumerico.test(pass))
        return 'A senha precisa conter pelo menos um número.';

    if (!regexCaracterEspecial.test(pass))
        return 'A senha precisa conter pelo menos um caractere especial.';

    return 'OK';
};

export const validadeName = (name) => {

    if (!name)
        return 'Nome completo precisa ser informado.';

    if (!validateName(name))
        return 'Nome não pode conter caracteres especiais.';

    return 'OK'
}

export const validadeEmail = (email) => {

    if (!email)
        return 'E-mail precisa ser informado.';

    if (!validateEmail(email))
        return 'O E-mail precisa ser um endereço válido.';

    return 'OK'
}

const validateName = (name) => {

    if (typeof name === 'string' && name.length >= 1) {
        if (/^[a-zA-ZÀ-ÖØ-öø-ÿ\s]+$/.test(name)) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

const validateEmail = (email) => {
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regexEmail.test(email);
};
