import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from './Header.module.css'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'; 

export default function Header(p) {

    const { title, alert, showCheck = false } = p;

    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <div className={$.title}>{title} {(showCheck && !alert) && <FontAwesomeIcon className={$.iconCheck} icon={faCheckCircle} />}</div>
                <div className={$.alert}>{
                    alert && 'Estamos verificando suas informações para garantir a segurança dos nossos clientes. Mantenha suas informações profissionais atualizadas. Obrigado.'
                }</div>
            </div>
        </div>)
}