import { React, useEffect } from 'react'
import $ from './Validade.module.css'
import { useState } from 'react'
import Button from '../../Components/Button/Button';
import DropDown from '../../Components/DropDown/DropDown';
import Avatar from '../../Components/Avatar/Avatar';
import { saveUser, searchUsers } from '../../Services/Database/User';
import { sendNotification } from '../../Firebase';

const status = [
    { key: 'P', value: 'Pendentes' },
    { key: 'V', value: 'Aprovados' },
    { key: 'R', value: 'Recusados' },
];

export default function Validade() {

    const [list, setList] = useState([]);
    const [filterStatus, setFilterStatus] = useState();

    useEffect(() => {
        searchUsers({ role: 'Advogado' }).then((result) => {
            setList(result.sort((a, b) => { return b.registerDate - a.registerDate }));
        })
    }, [])

    const handleValidation = (user, validated) => {
        var updated = user;
        user.validated = validated;
        saveUser(updated)
        var updatedList = list.filter(x => x !== user);
        updatedList.push(updated);
        setList(updatedList);
        sessionStorage.clear();
    }

    const handleNotification = (id) => {
        sendNotification(id, 'Preencha seu endereço e informações profissionais para podermos validar sua conta.');
    }

    const formatDate = (time) => {
        const date = new Date(parseInt(time));
        return date.toLocaleDateString('pt-br');
    }


    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <div className={$.block}>
                    <div className={$.blockBody}>
                        <div className={$.blockHeader}>
                            <div className={$.blockTitle}>
                                VALIDAÇÃO DE CONTAS
                            </div>
                        </div>
                        <div className={$.infos}>
                            <div>
                                <DropDown title={'Status'} list={status} set={setFilterStatus} get={filterStatus} />
                            </div>
                            <div className={$.grid}>
                                <div className={$.header}>
                                    <div className={$.row}>
                                        <div className={$.columnEmpty}></div>
                                        <div className={$.column}>Nome</div>
                                        <div className={$.column}>Email</div>
                                        <div className={$.column}>CPF</div>
                                        <div className={$.column}>Registro OAB</div>
                                        <div className={$.column}>Data Registro</div>
                                        <div className={$.actions}></div>
                                    </div>
                                </div>
                                <div className={$.body}>
                                    {
                                        list?.filter(x =>
                                            (!filterStatus) ||
                                            (filterStatus === 'P' && x.validated === null) ||
                                            (filterStatus === 'V' && x.validated) ||
                                            (filterStatus === 'R' && !x.validated)).length > 0 ? (
                                            list.filter(x =>
                                                (!filterStatus) ||
                                                (filterStatus === 'P' && x.validated === null) ||
                                                (filterStatus === 'V' && x.validated) ||
                                                (filterStatus === 'R' && !x.validated)).map((item) =>
                                                    <div className={$.row} key={item.id}>
                                                        <div className={$.columnEmpty}>
                                                            <Avatar picture={process.env.REACT_APP_FILE_SERVER + item.picture} name={item.name} />
                                                        </div>
                                                        <div className={$.column}>{item.name}</div>
                                                        <div className={$.column}>{item.email}</div>
                                                        <div className={$.column}>{item.cpf ? item.cpf : '-'}</div>
                                                        <div className={$.column}>{item.professionalCode ? item.professionalCode : '-'}</div>
                                                        <div className={$.column}>{formatDate(item.registerDate)}</div>
                                                        <div className={$.actions}>
                                                            {(!item.validated || item.validated === null) && <Button text={'Aprovar'} textColor={'var(--green'} color={'var(--green'} type='outline' size='small' onClick={() => handleValidation(item, true)} />}
                                                            {(item.validated || item.validated === null) && <Button text={'Recusar'} textColor={'var(--red'} color={'var(--red'} type='outline' size='small' onClick={() => handleValidation(item, false)} />}
                                                            <Button text={'Notificar'} textColor={'var(--primary_main'} color={'var(--primary_main'} type='outline' size='small' onClick={() => handleNotification(item.id)} />
                                                        </div>
                                                    </div>
                                                )
                                        ) : (
                                            <div className={$.emptyList}>Nenhum Registro</div>
                                        )
                                    }
                                </div>

                                <div className={$.footer}>
                                    Registros: {list?.filter(x =>
                                        (!filterStatus) ||
                                        (filterStatus === 'P' && x.validated === undefined) ||
                                        (filterStatus === 'V' && x.validated) ||
                                        (filterStatus === 'R' && !x.validated)).length}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}