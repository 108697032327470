import { React } from 'react';
import $ from './Avatar.module.css';

export default function Avatar(p) {

    const { name, picture, onClick, children, size = 40, color = 'var(--primary_light)' } = p;

    const initials = () => {

        if (!name)
            return '';

        const nameParts = name.split(' ');
        const firstName = nameParts[0];
        const lastName = nameParts[nameParts.length - 1];

        return firstName.charAt(0) + lastName.charAt(0);
    }

    return (
        <div className={$.container}>
            <div className={$.wrapper} onClick={() => onClick && onClick()} style={{ width: size, height: size, backgroundColor: color, fontSize: size / 2.5 }}>
                {picture ? <img alt='Avatar Foto' src={picture} className={$.picture} width='40' height='40' /> : <div>{initials()}</div>}
            </div>
            {children && children}
        </div >
    )
}