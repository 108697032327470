const possibleNames = {
    "DIREITO_CIVIL": ["Civil", "Direito Civil"],
    "DIREITO_CRIMINAL": ["Criminal", "Penal", "Direito Criminal", "Direito Penal"],    
    "DIREITO_DO_TRABALHO": ["Trabalhista", "Emprego", "Relações de Trabalho", "Laboral", "Trabalhista e Previdenciário", "Trabalho e Segurança do Trabalho", "Individual do Trabalho", "Coletivo do Trabalho", "Processual do Trabalho", "Direito do Trabalho"],
    "DIREITO_TRIBUTARIO": ["Tributário", "Fiscal", "Direito Tributário"],
    "DIREITO_EMPRESARIAL": ["Comercial", "Sociedades", "Propriedade Industrial", "Propriedade Intelectual", "Direito Empresarial"],
    "DIREITO_IMOBILIARIO": ["Imobiliário", "Urbanístico", "Notarial", "Registral", "Direito Imobiliário"],
    "DIREITO_ADMINISTRATIVO": ["Administração Pública", "Serviços Públicos", "Direito Administrativo"],
    "DIREITO_CONSTITUCIONAL": ["Constitucional", "Direito Constitucional"],
    "DIREITO_AMBIENTAL": ["Ambiental", "Sustentabilidade", "Direito Ambiental"],
    "DIREITO_DO_CONSUMIDOR": ["Consumidor", "Direito do Consumidor"],
    "DIREITO_INTERNACIONAL": ["Internacional", "Comunitário", "Direito Internacional"],
    "DIREITO_FAMILIA": ["Família", "Sucessões", "Direito da Família"],
    "DIREITO_SAUDE": ["Saúde", "Bioética", "Direito da Saúde"],
    "DIREITO_ANIMAL": ["Animal", "Direito Animal"],
    "DIREITO_PRIVACIDADE": ["Privacidade", "Proteção de Dados", "Direito da Privacidade"],
    "DIREITO_TECNOLOGIA": ["Tecnologia", "Internet", "Direito da Tecnologia"]
};

export const findJuridicalAreas = (text) => {
    const matchedAreas = [];

    Object.entries(possibleNames).forEach(([key, values]) => {
        const areaValues = values.map(value => value.toLowerCase());
        const regex = new RegExp(areaValues.join('|'), 'i');
        if (regex.test(text.toLowerCase())) {
            matchedAreas.push(key);
        }
    });

    return matchedAreas;
}
