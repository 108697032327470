const server = process.env.REACT_APP_SERVER;

export const createPaymentIntent = async () => {
    const response = await fetch(server + 'stripe/create-payment-intent', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            amount: 3990
        })
    });

    if (!response.ok) {
        throw new Error('Erro ao realizar a chamada POST'); // Trate erros de resposta
    }

    return await response.json();
};


export const createCustomer = async (email, name) => {
    const response = await fetch(server + 'stripe/create-customer', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email: email,
            name: name
        })
    });

    if (!response.ok) {
        throw new Error('Erro ao realizar a chamada POST'); // Trate erros de resposta
    }

    return await response.json();
};

export const createSubscription = async (customerId, priceId, trial = 0) => {
    const response = await fetch(server + 'stripe/create-subscription', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            customerId: customerId,
            priceId: priceId,
            trialDays: trial
        })
    });

    if (!response.ok) {
        throw new Error('Erro ao realizar a chamada POST'); // Trate erros de resposta
    }

    return await response.json();
};


export const cancelSubscription = async (subscriptionId) => {
    const response = await fetch(server + 'stripe/cancel-subscription', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            subscriptionId: subscriptionId,
        })
    });

    if (!response.ok) {
        throw new Error('Erro ao realizar a chamada POST'); // Trate erros de resposta
    }

    return await response.json();
};

export const renewSubscription = async (subscriptionId) => {
    const response = await fetch(server + 'stripe/renew-subscription', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            subscriptionId: subscriptionId,
        })
    });

    if (!response.ok) {
        throw new Error('Erro ao realizar a chamada POST'); // Trate erros de resposta
    }

    return await response.json();
};


export const getCustomer = async (email) => {
    const response = await fetch(server + 'stripe/get-customer', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email: email,
        })
    });

    if (!response.ok) {
        throw new Error('Erro ao realizar a chamada POST'); // Trate erros de resposta
    }

    var customer = await response.json();
    return customer;
};

export const getSubscription = async (customerId) => {
    const response = await fetch(server + 'stripe/get-subscription', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            customerId: customerId,
        })
    });

    if (!response.ok) {
        throw new Error('Erro ao realizar a chamada POST'); // Trate erros de resposta
    }

    var json = await response.json();
    var subscription = null;

    if (json.subscription && json.subscription.data.length > 0)
        subscription = json.subscription.data[0];

    return subscription;
};

export const getAllInvoices = async (customerId) => {
    const response = await fetch(server + 'stripe/get-all-invoice', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            customerId: customerId,
        })
    });

    if (!response.ok) {
        throw new Error('Erro ao realizar a chamada POST'); // Trate erros de resposta
    }

    return await response.json();
};

export const searchSubscription = async (email, name) => {

    var customer = await getCustomer(email);
    var isNew = false;

    if (!customer.customer) {
        customer = await createCustomer(email, name);
        isNew = true;
    }

    var subscription = await getSubscription(customer.customer.id);

    if (!subscription || isNew)
        subscription = await createSubscription(customer.customer.id, process.env.REACT_APP_PRICE_ID, isNew ? 30 : 0);

    return { customer: customer.customer.id, subscription: subscription.subscription ? subscription.subscription : subscription }
}

export const isPaymentSet = (subscription) => {

    if (!subscription)
        return false;

    if (subscription.default_payment_method)
        return true;

    return false;

}