export const formatDate = (value) => {
    const inputDate = value;
    const formattedDate = inputDate
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d{1,2})/, '$1/$2')
        .substring(0, 10);
    return formattedDate;
}

export const formatShortDate = (value) => {
    const inputDate = value;
    const formattedDate = inputDate
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .substring(0, 5);
    return formattedDate;
}