import { useState } from 'react';
import $ from './Filter.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { areas, orders } from '../../Utils/Professional';
import { React, useEffect, useRef } from 'react';

export default function Filter(params) {

    const { title, field, onChange } = params;

    const ref = useRef(null);
    const [expand, setExpand] = useState(false);
    const [list, setList] = useState([]);
    const [value, setValue] = useState();

    if (!list)
        if (field.includes('orders'))
            setList(orders);


    useEffect(() => {
        if (field.includes('orders'))
            setList(orders);
        if (field.includes('areas'))
            setList(areas)

        setValue(title);
    }, [])

    useEffect(() => {
        const handleClickOutside = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                setExpand(false)
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    return (
        <div className={$.container} ref={ref} onClick={() => setExpand(!expand)}>
            <div className={$.wrapper}>
                <div className={$.filtersBlock}>
                    <div className={$.filterBlockTitle}>
                        {value?.toUpperCase()}
                        <FontAwesomeIcon icon={!expand ? faChevronDown : faChevronUp} />
                    </div>
                    {
                        expand &&
                        <div className={$.filterBlockList}>
                            <div>
                                <div className={$.filterBlockItem} onClick={() => { setValue(title); setExpand(false); onChange('') }}>Nenhum</div>
                                {list?.map((item, index) =>
                                    <div
                                        key={index}
                                        className={$.filterBlockItem}
                                        onClick={() => { setValue(item.value); setExpand(false); onChange(item.key); }}>
                                        {item.value}
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}