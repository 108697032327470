import React from 'react';
import { Link } from 'react-router-dom';
import $ from './AvatarMenu.module.css'
import { logout } from '../../Firebase';

export default function AvatarMenu(p) {

    const { name, email } = p;

    const logoutUser = () => {
        logout();
        window.location.reload();
    }

    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <div className={$.name}>{name}</div>
                <div className={$.email}>{email}</div>
                <div className={$.options}>
                    <Link to={'/profile'}><div className={$.menu}>Perfil</div></Link>
                </div>
                <div className={$.menu} onClick={() => logoutUser()}>
                    Sair
                </div>
            </div>
        </div>
    )

}