const server = process.env.REACT_APP_SERVER;

export const sendEmail = async (email, subject, text) => {

    if (!email || !subject || !text)
        return;

    const emailData = {
        email: email,
        subject: subject,
        text: text,
    };

    try {
        const response = await fetch(server + 'email/send', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(emailData),
        });

        response.json();
    } catch (error) {
        console.error(error);
    }
}
