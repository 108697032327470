import { React, useState, useContext, useEffect } from 'react';
import $ from './ForgotPassword.module.css';
import TextField from '../../Components/TextField/TextField';
import Button from '../../Components/Button/Button';
import { sendPasswordResetEmail } from '../../Firebase';
import { v4 as uuidv4 } from 'uuid';
import { AlertContext } from '../../Context/AlertContext';
import { EMAIL_RECUPERACAO } from '../../Messages/Login';

export default function ForgotPassword(params) {

    const { handleClick } = params;

    const { alerts, setAlerts } = useContext(AlertContext);
    const [email, setEmail] = useState('')

    useEffect(() => {
    }, [])

    const sendEmail = () => {
        if (!email)
            return;

        sendPasswordResetEmail(email).then((success) => {
            if (success) {
                showAlert('success', EMAIL_RECUPERACAO)
                handleClick('signon')
            }
            else
                showAlert('error', 'Ocorreu um erro ao enviar email, tente novamente.')
        })
    }

    const showAlert = (type, message) => {
        const id = uuidv4();
        const newAlert = { id, message, type };
        setAlerts([...alerts, newAlert]);
    };

    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <div className={$.block}>
                    <div className={$.title}>Esqueceu sua senha?</div>
                    <div className={$.new}>Por favor, insira o endereço de e-mail associado à sua conta e nós lhe enviaremos um link para redefinir sua senha.</div>
                    <div className={$.field}>
                        <TextField title='E-mail' get={email} set={setEmail} autofocus autocomplete='new-password' />
                    </div>
                    <div className={$.button}>
                        <Button text='Enviar Solicitação' color='var(--texto1)' textColor='var(--background)' size='large' onClick={sendEmail} stretch />
                    </div>
                    <div className={$.or}>
                        <span className={$.line}>OU</span>
                    </div>
                    <div className={$.social}>
                        <span className={$.back} onClick={() => handleClick('signon')}>Voltar ao Login</span>
                    </div>
                </div>
            </div>
        </div>)
}
