import React from 'react';
import $ from './About.module.css'
import Header from '../_components/Header';
import TermoServico from '../_components/TermoServico';
import Privacidade from '../_components/Privacidade';
import Seguranca from '../_components/Seguranca';
import { Tab, Tabs } from '../../Components/Tabs/Tabs';
import { faBuildingColumns, faFileContract, faShieldAlt, faUserShield } from '@fortawesome/free-solid-svg-icons';
import Legify from './Legify';
import Termos from './Termos';
import { Helmet } from 'react-helmet';


export default function About() { 
    return (
        <>
            <Helmet>
                <title>Legify | Sobre</title>
                <meta httpEquiv="Cache-Control" content="max-age=31536000, public" />
            </Helmet>
            <div className={$.container}>
                <div className={$.wrapper}>
                    <Header
                        title={'Sobre'} />
                    <Tabs>
                        <Tab icon={faBuildingColumns} title={'O Legify'}>
                            <Legify />
                        </Tab>
                        {/* <Tab icon={faPeopleGroup} title={'A Equipe'}>
                    </Tab> */}
                        <Tab icon={faFileContract} title={'Termos de Serviço'}>
                            <Termos title={'TERMO DE SERVIÇO'}>
                                <TermoServico />
                            </Termos>
                        </Tab>
                        <Tab icon={faUserShield} title={'Privacidade'}>
                            <Termos title={'PRIVACIDADE'}>
                                <Privacidade />
                            </Termos>
                        </Tab>
                        <Tab icon={faShieldAlt} title={'Segurança'}>
                            <Termos title={'SEGURANÇA'}>
                                <Seguranca />
                            </Termos>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </>
    )
}