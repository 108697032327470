import { faBullhorn, faCheck } from '@fortawesome/free-solid-svg-icons'
import { Tab, Tabs } from '../../Components/Tabs/Tabs'
import Header from '../_components/Header'
import $ from './Admin.module.css'
import Validade from './Validade'
import NotifyAll from './NotifyAll'
import React from 'react'

export default function Admin() {
    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <Header
                    title={'Administração'} />
                <Tabs>
                    <Tab icon={faCheck} title={'Gestão de Advogados'}>
                        <Validade />
                    </Tab>
                    <Tab icon={faBullhorn} title={'Enviar Notificações'}>
                        <NotifyAll />
                    </Tab>
                </Tabs>
            </div>
        </div>
    )
}