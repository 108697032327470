import { React } from 'react';
import $ from './Switch.module.css';

export default function Switch(params) {

    const { checked = false, onClick } = params;

    return (
        <div className={$.container}>
            <div className={$.wrapper} onClick={() => onClick && onClick()} style={{ backgroundColor: checked ? 'var(--primary_main)' : 'var(--texto2)' }}>
                <div className={$.switch} style={{ left: checked ? '2px' : 'calc(100% - 17px)' }} />
            </div>
        </div>
    )
}
