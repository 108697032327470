import $ from './Pie.module.css';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Chart from 'react-apexcharts';

export default function Pie(params) {

    const { title, values } = params;
    const [labels, setLabels] = useState([]);
    const [series, setSeries] = useState([]);
    const [label, setLabel] = useState();
    const [value, setValue] = useState();

    const options = {
        chart: {
            events: {
                dataPointMouseEnter: function (event, chartContext, { dataPointIndex }) {
                    setLabel(labels[dataPointIndex])
                    setValue(series[dataPointIndex])
                },
                dataPointMouseLeave: function () {
                    setLabel('Total')
                    setValue(series.reduce((accumulator, currentValue) => accumulator + currentValue, 0))
                }
            }
        },
        stroke: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        labels: labels,
        plotOptions: {
            pie: {
                donut: {
                    size: '85%',
                },
            },
        },
        tooltip: {
            enabled: true,
        },
    }

    useEffect(() => {
        var lbs = values?.map(item => item.key)
        setLabels(lbs);
        var sr = values?.map(item => item.value)
        setSeries(sr);

        setLabel('Total')
        setValue(sr?.reduce((accumulator, currentValue) => accumulator + currentValue, 0))

    }, [values])

    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <div className={$.title}>{title}</div>
                <div className={$.infos}>
                    {
                        series &&
                        <div className={$.pie}>
                            <Chart options={options} series={series} type="donut" width={380} />
                            {label &&
                                <div className={$.pieFloat}>
                                    <div className={$.pieLabel}>
                                        {label}
                                    </div>
                                    <div className={$.pieValue}>
                                        {value}
                                    </div>
                                </div>}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
