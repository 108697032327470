import $ from './General.module.css'
import Avatar from '../../Components/Avatar/Avatar';
import { React, useEffect, useState, useContext, useRef } from 'react';
import { UserContext } from '../../Context/UserContext';
import TextField from '../../Components/TextField/TextField';
import Button from '../../Components/Button/Button';
import { getAddress, getImageUrl, saveImage } from '../../Services/User';
import DropDown from '../../Components/DropDown/DropDown';
import { states } from '../../Utils/Address';
import { sexs } from '../../Utils/Professional';
import Loading from '../../Components/Loading/Loading';
import { v4 as uuidv4 } from 'uuid';
import { AlertContext } from '../../Context/AlertContext';
import { Helmet } from 'react-helmet';

const MAX_IMAGE_SIZE = 80 * 1024;
const validExtensions = ['jpeg', 'jpg', 'png'];

export default function General(params) {

    const { handleChanges } = params;

    const { user } = useContext(UserContext);
    const [name, setName] = useState('');
    const [sex, setSex] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [cep, setCep] = useState('');
    const [address, setAddress] = useState('');
    const [number, setNumber] = useState('');
    const [neighborhood, setNeighborhood] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('Brasil');
    const [about, setAbout] = useState('');
    const [uploading, setUploading] = useState(false);
    const { alerts, setAlerts } = useContext(AlertContext);

    const fileRef = useRef(null);

    const handleClickUpload = () => {
        fileRef.current.click();
    }

    const showAlert = (type, message) => {
        const id = uuidv4();
        const newAlert = { id, message, type };
        setAlerts([...alerts, newAlert]);
    };

    const handleUpload = (e) => {
        const file = e.files[0];
        if (file) {
            const extension = file.name.split('.').pop().toLowerCase();
            if (!validExtensions.includes(extension)) {
                showAlert('error', 'Extensão do arquivo invalida. Utilize somente imagens JPEG, JPG, ou PNG.')
                setUploading(false);
                return;
            }
            setUploading(true)
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const img = new Image();
                img.src = reader.result;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const scaleFactor = Math.min(1, MAX_IMAGE_SIZE / file.size);
                    canvas.width = img.width * scaleFactor;
                    canvas.height = img.height * scaleFactor;
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                    const base64 = canvas.toDataURL(file.type);
                    saveImage('picture_' + user.id, base64).then((response) => {
                        const updatedUser = { ...user, picture: getImageUrl(response.url) };
                        handleChanges(updatedUser, false);
                        setUploading(false);
                    });
                };
            };
        }
    };


    const handleButton = () => {
        var updatedUser = user;
        updatedUser.name = name ? name : '';
        updatedUser.sex = sex ? sex : '';
        updatedUser.contact = contact ? contact : '';
        updatedUser.cep = cep ? cep : '';
        updatedUser.address = address ? address : '';
        updatedUser.number = number ? number : '';
        updatedUser.neighborhood = neighborhood ? neighborhood : '';
        updatedUser.city = city ? city : '';
        updatedUser.state = state ? state : '';
        updatedUser.country = country ? country : '';
        updatedUser.about = about ? about : '';
        handleChanges(updatedUser);
    }

    useEffect(() => {
        if (user) {
            setName(user?.name)
            setSex(user?.sex)
            setEmail(user?.email)
            setContact(user?.contact)
            setCep(user?.cep)
            setAddress(user?.address)
            setNumber(user?.number)
            setNeighborhood(user?.neighborhood)
            setCity(user?.city)
            setState(user?.state)
            setAbout(user?.about)
        }

    }, [user])

    const loadAddress = () => {
        if (cep)
            getAddress(cep).then((data) => {
                if (data) {
                    setAddress(data.logradouro)
                    setNeighborhood(data.bairro)
                    setCity(data.localidade)
                    setState(data.uf)
                }
                else {
                    setAddress('')
                    setNeighborhood('')
                    setCity('')
                    setState('')
                }
            });
    }

    return (
        <>
            <Helmet>
                <title>Legify | Perfil</title>
            </Helmet>
            <div className={$.container}>
                <div className={$.wrapper}>
                    <div className={$.upload}>
                        <Avatar name={user?.name} picture={process.env.REACT_APP_FILE_SERVER + user?.picture} size={150} onClick={() => handleClickUpload()}>
                            <Loading enabled={uploading}>
                                <input type="file" hidden ref={fileRef} accept=".jpeg, .jpg, .png" onChange={(e) => handleUpload(e.target)} />
                            </Loading>
                        </Avatar>
                        <div className={$.uploadInfos}>
                            <span>Apenas *.jpeg, *.jpg, *.png</span>
                            <span>Tamanho maximo de 1MB.</span>
                        </div>
                    </div>

                    <div className={$.block}>
                        <div className={$.blockBody}>
                            <div className={$.blockHeader}>
                                <div className={$.blockTitle}>
                                    DADOS PESSOAIS
                                </div>
                            </div>
                            <div className={$.infos}>
                                <div className={$.field}>
                                    <TextField title='Nome' get={name} set={setName} />
                                </div>
                                <div className={$.field}>
                                    <TextField title='Email' get={email} disabled />
                                </div>
                                <div className={$.field}>
                                    <DropDown title='Gênero' get={sex} set={setSex} list={sexs} />
                                </div>
                                <div className={$.field}>
                                    <TextField title='Celular' get={contact} set={setContact} format='phone' />
                                </div>
                                <div className={$.field}>
                                    <TextField title='CEP' get={cep} set={setCep} format='cep' onBlur={loadAddress} />
                                </div>
                                <div className={$.field}>
                                    <TextField title='Endereço' get={address} set={setAddress} />
                                </div>
                                <div className={$.field}>
                                    <TextField title='Número' get={number} set={setNumber} />
                                </div>
                                <div className={$.field}>
                                    <TextField title='Bairro' get={neighborhood} set={setNeighborhood} />
                                </div>
                                <div className={$.field}>
                                    <TextField title='Cidade' get={city} set={setCity} />
                                </div>
                                <div className={$.field}>
                                    <DropDown title='Estado' get={state} set={setState} list={states} />
                                </div>
                                <div className={$.field}>
                                    <TextField title='Pais' get={country} set={setCountry} />
                                </div>
                                <div className={$.command}>
                                    <Button text={'Salvar'} color='var(--primary_main)' textColor='var(--white)' onClick={handleButton} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>)
}