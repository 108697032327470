import React from 'react';
import { useEffect } from "react";

export default function AppMaster(params) {

    const { children } = params;

    useEffect(() => {
        const theme = localStorage.getItem('theme');

        if (theme)
            document.documentElement.className = theme;
        else
            document.documentElement.className = 'dark blue';
    }, [])

    return (<div>
        {children}
    </div>)
}