const server = process.env.REACT_APP_SERVER;

export const tableRows = async (table) => {
    try {
        const response = await fetch(server + 'table/rows', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(table)
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error('Erro ao contar as tabelas');
        }
    } catch (error) {
        throw new Error(error);
    }
};