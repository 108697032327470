export const detectCardType = (cardNumber) => {
    const visaRegEx = /^4/;
    const mastercardRegEx = /^5[1-5]/;
    const amexRegEx = /^3[47]/;
    const discoverRegEx = /^6(?:011|5[0-9]{2})/;
    const dinersRegEx = /^3(?:0[0-5]|[68][0-9])[0-9]{4}$/;
    const jcbRegEx = /^(?:2131|1800|35\d{3})\d{11}$/;

    if (visaRegEx.test(cardNumber)) {
        return "Visa";
    } else if (mastercardRegEx.test(cardNumber)) {
        return "Mastercard";
    } else if (amexRegEx.test(cardNumber)) {
        return "American Express";
    } else if (discoverRegEx.test(cardNumber)) {
        return "Discover";
    } else if (dinersRegEx.test(cardNumber)) {
        return "Diners Club";
    } else if (jcbRegEx.test(cardNumber)) {
        return "JCB";
    } else {
        return "Unknown";
    }
};

export const formatCardNumber = (value) => {
    const inputNumber = value;
    const formattedNumber = inputNumber
        .replace(/\D/g, '') // remove non-digit characters
        .replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, "$1 $2 $3 $4")
        .trim() // remove leading/trailing whitespace
        .substring(0, 19); // limit to 19 characters (max length of a credit card number)

    return formattedNumber;
};
