import { React, useState } from 'react';
import $ from './Login.module.css';
import Logo from './../../Images/logo_rocket.webp';
import Adv from './../../Images/advogados.webp';
import { useNavigate } from 'react-router-dom'
import SignIn from './SignIn';
import SignOn from './SignOn';
import ForgotPassword from './ForgotPassword';
import PreSignIn from './PreSignIn';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet';

export default function Login() {

    const [step, setStep] = useState('signon');
    const [role, setRole] = useState('client');
    const navigate = useNavigate();

    const imagemLogo = useMemo(() => Logo, []);
    const imagemAdv = useMemo(() => Adv, []);


    const chooseRole = (r) => {
        setRole(r);
        setStep('signin');
    }

    return (
        <>
            <Helmet>
                <title>Legify | Login</title>
                <meta httpEquiv="Cache-Control" content="max-age=31536000, public" />
            </Helmet>
            <div className={$.container} id={'form'}>
                <div className={$.wrapper}>
                    <div className={$.presentation}>
                        <div className={$.header}>
                            <img alt='Legify Logo' className={$.logo} src={imagemLogo} width='40' height='40' onClick={() => navigate('/')} />
                        </div>
                        <div className={$.content}>
                            <div className={$.contentTitle}>Bem-vindo ao Legify</div>
                            <img alt='Login' src={imagemAdv} className={$.contentImage} width='720' height='540' />
                        </div>
                    </div>
                    <div className={$.block}>
                        {step === 'presignin' && <PreSignIn handleClick={setStep} handleRole={chooseRole} />}
                        {step === 'signin' && <SignIn handleClick={setStep} role={role} />}
                        {step === 'signon' && <SignOn handleClick={setStep} />}
                        {step === 'forgot' && <ForgotPassword handleClick={setStep} />}
                    </div>
                </div>
            </div>
        </>)
}
