import $ from './Billing.module.css'
import { useContext, useState } from 'react';
import { UserContext } from '../../Context/UserContext';
import Button from '../../Components/Button/Button';
import { faChevronLeft, faChevronRight, faStar } from '@fortawesome/free-solid-svg-icons';
import StripeCheckout from './StripeCheckout';
import { React, useEffect } from 'react';
import { cancelSubscription, getAllInvoices, getSubscription, isPaymentSet, renewSubscription } from '../../Services/Stripe';
import { convertToReal } from '../../Utils/Monetary';
import { v4 as uuidv4 } from 'uuid';
import { AlertContext } from '../../Context/AlertContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Switch from '../../Components/Switch/Switch';

const rows = 9;

export default function Billing() {

    const { user, setUser } = useContext(UserContext);
    const { alerts, setAlerts } = useContext(AlertContext);
    const [subscriptions, setSubscriptions] = useState();
    const [page, setPage] = useState(1);

    const showAlert = (type, message) => {
        const id = uuidv4();
        const newAlert = { id, message, type };
        setAlerts([...alerts, newAlert]);
    };

    const successPremium = (customer) => {
        sessionStorage.clear();
        getSubscription(customer).then((subscription) => {
            var data = user;
            if (subscription)
                data.subscription = subscription
            setUser({ ...data });
            showAlert('success', 'Bem vindo ao nosso clube de membros, esperamos que aproveite todos os benefícios.')
        })
    }

    const cancelPlan = () => {
        if (!user.customerId)
            return;

        getSubscription(user.customerId).then((subscription) => {
            if (subscription)
                cancelSubscription(subscription.id).then((result) => {
                    if (result) {
                        var updatedUser = user;
                        updatedUser.subscription = result.subscription
                        showAlert('info', 'A renovação automatica foi desativada.')
                    }
                    else {
                        showAlert('error', 'Ocorreu um problema ao desativar sua renovação.')
                    }
                })
        })
    }

    const renewPlan = () => {
        if (!user.customerId)
            return;

        getSubscription(user.customerId).then((subscription) => {
            if (subscription)
                renewSubscription(subscription.id).then((result) => {
                    if (result) {
                        var updatedUser = user;
                        updatedUser.subscription = result.subscription
                        showAlert('success', 'A renovação automatica foi ativada.')
                    }
                    else {
                        showAlert('error', 'Ocorreu um problema ao desativar sua renovação.')
                    }
                });
        })
    }

    const totalPages = (list, rows) => {
        var t = list.length / rows;
        return Math.ceil(t);
    }

    const changePage = (s) => {
        var p = page + s;
        if (p > totalPages(subscriptions, rows))
            p = p - 1;
        else
            if (p <= 0)
                p = 1;
        setPage(p)
    }

    const loadInvoices = () => {
        if (!user.customerId)
            return;

        getAllInvoices(user.customerId).then((object) => {
            if (object)
                setSubscriptions(object.invoices.data)
        });
    }

    useEffect(() => {
        loadInvoices();

        if (!user.subscription) {
            getSubscription(user.email).then((object) => {
                var data = user;
                if (object) {
                    data.subscription = object.subscription;
                }
                setUser({ ...data });
            })
        }

    }, [user])

    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <div className={$.infos}>
                    {/* Your Plan */}
                    <div className={$.block}>
                        <div className={$.blockHeader}>
                            <div className={$.blockTitle}>
                                SEU PLANO
                            </div>
                        </div>
                        <div className={$.blockBody}>
                            {
                                user.subscription?.status === 'active' || user.subscription?.status === 'trialing' ?
                                    <div>
                                        <div className={$.plan}>
                                            <div>Premium <FontAwesomeIcon className={$.star} icon={faStar} /></div>
                                        </div>
                                        <div className={$.planInfos}>
                                            <div className={$.planInfosLine}>
                                                <div className={$.planInfosLabel}>Data de adesão: </div>
                                                <div className={$.planInfosValue}>{new Date(user.subscription?.start_date * 1000).toLocaleDateString('pt-br')}</div>
                                            </div>
                                            <div className={$.planInfosLine}>
                                                <div className={$.planInfosLabel}>Vencimento do plano: </div>
                                                <div className={$.planInfosValue}>{new Date(user.subscription?.current_period_end * 1000).toLocaleDateString('pt-br')}</div>
                                            </div>
                                            <div className={$.planInfosLine}>
                                                <div className={$.planInfosLabel}>Renovação automatica: </div>
                                                <div className={$.planInfosValue}>
                                                    {
                                                        <Switch
                                                            checked={!user.subscription?.cancel_at_period_end}
                                                            onClick={() =>
                                                                user.subscription?.cancel_at_period_end ? renewPlan() : cancelPlan()
                                                            }
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            <div className={$.planInfosLine}>
                                                <div className={$.planInfosLabel}>Proxima renovação: </div>
                                                <div className={$.planInfosValue}>
                                                    {user.subscription?.cancel_at_period_end ?
                                                        <div style={{ color: 'var(--red)', fontWeight: 'bold' }}>desativada</div> :
                                                        new Date(user.subscription?.current_period_end * 1000).toLocaleDateString('pt-br')}</div>
                                            </div>
                                        </div>
                                        <div className={$.planHint}>
                                            A Legify não guarda nenhum dado de pagamento dos planos dos usuários. Essa responsabilidade é totalmente da Stripe, nosso parceiro de processamento de pagamentos. A Stripe é uma empresa líder em serviços de pagamentos online, conhecida pela sua segurança e confiabilidade.
                                        </div>
                                        <div className={$.planHint}>
                                            A renovação automática acontece na data informada acima, caso queira cancelar sua inscrição, basta desativar a renovação automática, assim você poderá desfrutar dos benefícios do seu plano até {new Date(user.subscription?.current_period_end * 1000).toLocaleDateString('pt-br')}.
                                        </div>                                        
                                    </div> :
                                    <div className={$.planSell}>
                                        <div>
                                            Torne-se parceiro ainda hoje e faça parte do nosso diretório de profissionais, e tenha acesso a recursos exclusivos que o ajudarão a ampliar sua presença online no mercado jurídico. Não perca a oportunidade de se tornar um parceiro do Legify hoje mesmo.
                                        </div>
                                        <div className={$.planPrice}>
                                            Apenas <span className={$.highlight}>R$39.90</span> por mês
                                        </div>
                                    </div>
                            }
                            {
                                user.subscription && !isPaymentSet(user.subscription) &&
                                <StripeCheckout onSuccess={successPremium} />
                            }
                        </div>
                    </div>

                </div>

                <div className={$.history}>
                    <div className={$.block}>
                        <div className={$.blockHeader}>
                            <div className={$.blockTitle}>
                                HISTÓRICO DE PAGAMENTO
                            </div>
                        </div>
                        <div className={$.blockBody}>
                            {subscriptions ?
                                <div className={$.list}>
                                    <div className={`${$.listRow} ${$.listHeader}`}>
                                        <div className={$.listColumn}>TRANSAÇÃO</div>
                                        <div className={$.listColumnRight}>DATA</div>
                                        <div className={$.listColumnRight}>VALOR</div>
                                    </div>
                                    {
                                        subscriptions?.slice((page - 1) * rows, (page - 1) * rows + rows)?.map((sub, i) =>
                                            <div className={$.listRow} key={i}>
                                                <div className={$.listColumn}>
                                                    {sub.number}
                                                </div>
                                                <div className={$.listColumnRight}>
                                                    {new Date(sub.created * 1000).toLocaleDateString('pt-br')}
                                                </div>
                                                <div className={$.listColumnRight}>
                                                    {convertToReal('R$', sub.total)}
                                                </div>
                                            </div>
                                        )
                                    }
                                    <div className={$.listPages}>
                                        <div className={$.listPage}>
                                            <Button icon={faChevronLeft} textColor='var(--texto2)' onClick={() => changePage(-1)} />
                                            <div>{page}/{totalPages(subscriptions, rows)}</div>
                                            <Button icon={faChevronRight} textColor='var(--texto2)' onClick={() => changePage(1)} />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className={$.listEmpty}>Nenhuma cobrança até o momento.</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >)
}