import { React } from 'react';
import { useState, useRef, useEffect } from 'react';
import { formatCardNumber } from '../../Utils/CreditCard';
import { formatDate, formatShortDate } from '../../Utils/Date';
import { formatPhoneNumber } from '../../Utils/Phone';
import { formatCEP } from '../../Utils/Address';
import { formatCPF, formatOAB } from '../../Utils/Professional';
import $ from './TextField.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faXmark } from '@fortawesome/free-solid-svg-icons';

export default function TextField(p) {

    const {
        title,
        type = 'text',
        color = 'var(--texto1)',
        background = 'transparent',
        labelBackground,
        borderColor = 'var(--texto2)',
        border = 'solid',
        rows = 4,
        get,
        set,
        format,
        autocomplete = "on",
        autofocus = false,
        disabled = false,
        placeholder = '',
        onBlur,
        clear = true,
        validate,
        onKeyDown,
        limit = 150
    } = p;

    const [error, setError] = useState(false);

    const inputRef = useRef(null);
    const [focused, setFocused] = useState(false)

    const focus = () => {
        setFocused(true);
        setError(false);
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }

    const formatText = (value) => {
        if (format === 'date')
            return formatDate(value);

        if (format === 'short-date')
            return formatShortDate(value);

        if (format === 'card')
            return formatCardNumber(value);

        if (format === 'phone')
            return formatPhoneNumber(value);

        if (format === 'cep')
            return formatCEP(value);

        if (format === 'cpf')
            return formatCPF(value);

        if (format === 'oab')
            return formatOAB(value);

        return value;
    }

    const executeBlur = () => {
        setFocused(false)
        if (onBlur)
            onBlur()
    }

    const clearHandler = () => {
        set('');
    }

    useEffect(() => {
        if (autofocus && inputRef.current) {
            inputRef.current.focus();
        }
    }, [autofocus]);

    useEffect(() => {
        if (validate)
            if (get.length > 0)
                setError(!validate(false))
            else
                setError(false)
    }, [get])

    return (
        <div className={$.container}>
            <div className={$.wrapper} onClick={() => focus()} onBlur={() => setFocused(false)}>
                <span className={$.label}
                    style={{
                        top: focused || get ? '-8px' : '18px',
                        color: error ? 'var(--error)' : (focused && !disabled ? 'var(--texto1)' : borderColor ? borderColor : 'var(--texto2)'),
                        background: focused || get ? labelBackground ? labelBackground : 'var(--destaque)' : 'transparent'
                    }}
                >{title}</span>
                {
                    type === 'textarea' ?
                        <textarea ref={inputRef}
                            value={get}
                            className={$.input}
                            type={type}
                            onFocus={() => focus()}
                            onBlur={() => { executeBlur() }}
                            onChange={(e) => set && set(formatText(e.target.value))}
                            rows={rows}
                            onKeyDown={() => onKeyDown && onKeyDown()}
                            autoComplete={autocomplete}
                            disabled={disabled}
                            placeholder={placeholder}
                            style={{ color: color, backgroundColor: background, border: ('1px ' + border + (error ? ' var(--error)' : ' ' + borderColor)) }}
                        />
                        :
                        <input ref={inputRef}
                            value={get}
                            className={$.input}
                            type={type}
                            onFocus={() => focus()}
                            onBlur={() => { executeBlur() }}
                            onChange={(e) => set && set(formatText(e.target.value))}
                            id={title}
                            onKeyDown={(e) => onKeyDown && onKeyDown(e)}
                            autoComplete={autocomplete}
                            disabled={disabled}
                            placeholder={placeholder}
                            maxLength={limit}
                            style={{ color: color, backgroundColor: background, border: ('1px ' + border + (error ? ' var(--error)' : ' ' + borderColor)) }}
                        />
                }
                {clear && !disabled && <FontAwesomeIcon className={$.clear} icon={faXmark} onClick={() => clearHandler()} style={{ cursor: 'pointer', color: error ? 'var(--error)' : 'var(--texto2)' }} />}
                {disabled && <FontAwesomeIcon className={$.clear} icon={faLock} style={{ cursor: 'not-allowed', color: error ? 'var(--error)' : 'var(--texto2)' }} />}
            </div>
        </div >
    )
}
