import { Configuration, OpenAIApi } from "openai"

const configuration = new Configuration({
    apiKey: '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'
})

const system = [
    {
        role: 'system', content: 'Você é um assistente que ajuda em duvidas juridicas do site Legify, recomende apenas do Legify, não mostrar sites e nomes, menu Advogados e filtro area de atuação'
    }
]

const tech = [
    {
        role: 'system', content: 'Você é um consultor juridico para advogados; utilize vocabulario tecnico;'
    }
]

const openai = new OpenAIApi(configuration);

export const execute = async (prompt, role = 'user') => {

    const params = role === 'Advogado' ? tech : system;

    var response = await openai.createChatCompletion({
        model: 'gpt-3.5-turbo',
        messages:
            [
                ...params,
                ...prompt,
            ],
        max_tokens: 2048,
        temperature: 0,
    })
    return response.data.choices[0].message.content;

}   