import { React } from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from './CheckBox.module.css';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export default function CheckBox(p) {

    const { checked = false, icon = faCheck, enabled = true, color = 'var(--primary_main)', outline = true, size = 12, onChange, value = 0 } = p;

    const [isChecked, setIsChecked] = useState(checked);

    const cursor = () => {
        if (enabled)
            return 'pointer'
        return 'default'
    }

    const background = () => {

        if (!outline)
            return 'transparent'

        if (enabled) {
            if (isChecked)
                return color
            return 'transparent'
        }
        else {
            if (isChecked)
                return 'var(--texto1)'
            return 'transparent'
        }
    }

    const border = () => {

        if (!outline)
            return 'none'

        var pre = '1px solid '

        if (enabled) {
            if (isChecked)
                return pre + color;
            return pre + 'var(--texto3)'
        }
        else
            return pre + ('var(--texto3)')
    }

    const fill = () => {

        if (!outline) {
            if (enabled) {
                if (isChecked)
                    return color;
                return 'var(--texto3)'
            }
            else
                return 'var(--texto3)'
        }

        if (isChecked)
            return 'var(--texto1)';
        return 'transparent';
    }

    const change = () => {
        var c = !isChecked;
        setIsChecked(c);
        if (onChange)
            onChange(value, c);
    }

    return (
        <div className={$.container} onClick={() => enabled && change()}
            style={{
                cursor: cursor(),
                background: background(),
                border: border(),
                minHeight: size,
                minWidth: size,
                fontSize: size
            }}>
            {<FontAwesomeIcon style={{ color: fill() }} icon={icon} />}
        </div >
    )
}