import React from 'react';
import $ from './Privacidade.module.css';

export default function Privacidade() {
    return (
        <div className={$.container}> 
            <div className={$.wrapper}>
                <h1>Política de Privacidade</h1>
                <p>A sua privacidade é importante para nós. Este termo de privacidade explica como coletamos, usamos, divulgamos e protegemos suas informações pessoais em nossa plataforma online de tira-dúvidas jurídicas e pesquisa de advogados.</p>
                <h2>1. Informações Pessoais Coletadas</h2>
                <p>Coletamos informações pessoais que você nos fornece quando cria uma conta em nossa plataforma, solicita informações jurídicas ou pesquisa de advogados, ou entra em contato conosco. As informações pessoais que podemos coletar incluem:</p>
                <ul>
                    <li>Nome</li>
                    <li>Endereço de e-mail</li>
                    <li>Endereço residencial</li>
                    <li>Informações de pagamento</li>
                    <li>Informações de perfil profissional (no caso de advogados cadastrados)</li>
                </ul>
                <p>Também podemos coletar informações de uso da plataforma, como páginas visitadas, tempo gasto no site e outras informações de uso.</p>
                <h2>2. Uso de Informações Pessoais</h2>
                <p>Usamos suas informações pessoais para fornecer nossos serviços, responder a suas solicitações e melhorar a plataforma. Também podemos usar suas informações pessoais para entrar em contato com você sobre nossos serviços ou enviar informações relevantes.</p>
                <p>Além disso, podemos usar suas informações pessoais para fins de marketing e promoção. Caso você não queira receber essas comunicações, você pode optar por não receber futuras comunicações de marketing.</p>
                <h2>3. Compartilhamento de Informações Pessoais</h2>
                <p>Não compartilhamos suas informações pessoais com terceiros, exceto conforme necessário para fornecer nossos serviços ou conforme exigido por lei. Podemos compartilhar suas informações pessoais com os advogados cadastrados em nossa base de dados para que eles possam responder às suas solicitações de informações jurídicas ou oferecer seus serviços.</p>
                <h2>4. Segurança de Informações Pessoais</h2>
                <p>Tomamos medidas razoáveis para proteger suas informações pessoais contra perda, uso indevido e acesso não autorizado, divulgação, alteração ou destruição. No entanto, não podemos garantir a segurança absoluta de suas informações pessoais.</p>
                <h2>5. Cookies e Tecnologias de Rastreamento</h2>
                <p>Usamos cookies e outras tecnologias de rastreamento para melhorar a plataforma e personalizar sua experiência. Podemos coletar informações sobre suas atividades de navegação, como o endereço IP, tipo de navegador e idioma, para personalizar sua experiência na plataforma. Você pode desativar os cookies em seu navegador, mas isso pode afetar a funcionalidade da plataforma.</p>
                <h2>6. Mudanças neste Termo de Privacidade</h2>
                <p>Podemos atualizar este termo de privacidade a qualquer momento, a nosso critério. Se fizermos alterações significativas neste termo, notificaremos você por meio de um aviso na plataforma ou por e-mail. O uso continuado da plataforma após a notificação da alteração constitui sua concordância com as alterações feitas.</p>

                <h2>7. Seus Direitos</h2>
                <p>Você tem o direito de acessar, corrigir ou excluir suas informações pessoais a qualquer momento. Você também pode solicitar que paremos de usar suas informações pessoais para fins de marketing. Para exercer esses direitos, entre em contato conosco por meio das informações de contato abaixo.</p>
                <h2>8. Informações de Contato</h2>
                <p>Se você tiver alguma dúvida ou preocupação sobre este termo de privacidade ou sobre o uso de suas informações pessoais, entre em contato conosco através dos seguintes meios:</p>
                <p>E-mail: [contato@legify.com.br]</p>
                <p>Este termo de privacidade entra em vigor a partir da data em que você aceita os termos de uso da plataforma e continua em vigor até que seja alterado ou rescindido.</p>
            </div>
        </div>
    )
}