import { React, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ContentMaster from "./Pages/ContentMaster";
import { UserContext } from './Context/UserContext';
import { AlertContext } from './Context/AlertContext';
import { ProsContext } from './Context/ProsContext';
import ProfileSettings from './Pages/ProfileSettings/ProfileSettings';
import Login from './Pages/Login/Login';
import Professionals from './Pages/Professionals/Professionals';
import Landing from './Pages/Landing/Landing';
import Alert from './Components/Alert/Alert';
import $ from './App.module.css';
import AppMaster from './AppMaster';
import About from './Pages/About/About';
import Contact from './Pages/Contact/Contact';
import Admin from './Pages/Admin/Admin';
import Dashboard from './Pages/Dashboard/Dashboard';
import Question from './Pages/Question/Question';

function App() {

  const [user, setUser] = useState();
  const [pros, setPros] = useState([]);
  const [alerts, setAlerts] = useState([]);

  const handleAlertClose = (id) => {
    if (id) {
      setAlerts(prevAlerts => prevAlerts.filter(alert => alert.id !== id));
    }
  };

  return (
    <BrowserRouter>
      <AlertContext.Provider value={{ alerts, setAlerts }}>
        <UserContext.Provider value={{ user, setUser }}>
          <ProsContext.Provider value={{ pros, setPros }}>
            <AppMaster>
              <div className={$.alerts}>
                {
                  alerts.map(alert =>
                    <Alert key={alert.id} id={alert.id} type={alert.type} text={alert.message} onClose={handleAlertClose} />
                  )
                }
              </div>
              <Routes>
                <Route element={<Landing />} path='*' />
                <Route element={<Login />} path='/login' />
                <Route element={<ContentMaster><ProfileSettings /></ContentMaster>} path='/profile' />
                <Route element={<ContentMaster><Professionals /></ContentMaster>} path='/professionals' />
                <Route element={<ContentMaster><About /></ContentMaster>} path='/about' />
                <Route element={<ContentMaster><Contact /></ContentMaster>} path='/contact' />
                <Route element={<ContentMaster><Admin /></ContentMaster>} path='/administration' />
                <Route element={<ContentMaster><Dashboard /></ContentMaster>} path='/dashboard' />
                <Route element={<ContentMaster><Question presentation={false} /></ContentMaster>} path='/question' />
              </Routes>
            </AppMaster>
          </ProsContext.Provider>
        </UserContext.Provider>
      </AlertContext.Provider>
    </BrowserRouter>
  );
}

export default App;
