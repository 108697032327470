import { React } from 'react';
import { useEffect, useRef } from 'react';
import $ from './Popup.module.css';

export default function Popup(p) {

    const { children, position = 'center', onBlur } = p;

    const ref = useRef(null);

    const positionStyle = () => {
        if (position === 'center')
            return {
            }
        if (position === 'right')
            return {
                right: '0px'
            }
        if (position === 'left')
            return {
                left: '0px'
            }
    }

    useEffect(() => {
        const handleClickOutside = event => {
            if (ref.current && !ref.current.contains(event.target)) {
                onBlur && onBlur();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    return (
        <div className={$.container} style={positionStyle()} ref={ref}>
            <div className={$.arrow} />
            <div>
                {children}
            </div>
        </div>
    )
}
