import { React, useState, useContext } from 'react';
import $ from './SignIn.module.css';
import TextField from '../../Components/TextField/TextField';
import Button from '../../Components/Button/Button';
import Badge from '../../Components/Badge/Badge';
import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { useNavigate } from 'react-router-dom'
import { createAccount, deleteAccount, signInWithGoogle, signInWithFacebook, sendNotification } from '../../Firebase';
import { validadePassword, validadeName, validadeEmail } from '../../Utils/Login';
import Dialog from '../../Components/Dialog/Dialog';
import TermoServico from '../_components/TermoServico';
import Privacidade from '../_components/Privacidade';
import Seguranca from '../_components/Seguranca';
import { v4 as uuidv4 } from 'uuid';
import { AlertContext } from '../../Context/AlertContext';
import { saveUser } from '../../Services/Database/User';
import { EMAIL_CONFIRMACAO, EMAIL_EM_USO, EMAIL_INVALIDO, ERRO_CRIAR, SENHA_FRACA } from '../../Messages/Login';

export default function SignIn(params) {

    const { handleClick, role } = params;

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [popup, setPopup] = useState();
    const { alerts, setAlerts } = useContext(AlertContext);

    const navigate = useNavigate();

    const validade = () => {
        if (evalName() && evalEmail() && evalPassword())
            return true;
        return false;
    }

    const evalName = (show = true) => {
        const validation = validadeName(name);
        if (validation !== 'OK') {
            if (show)
                showAlert('info', validation);
            return false;
        }
        return true;
    }

    const evalEmail = (show = true) => {
        const validation = validadeEmail(email);
        if (validation !== 'OK') {
            if (show)
                showAlert('info', validation);
            return false;
        }
        return true;
    }

    const evalPassword = (show = true) => {
        const validation = validadePassword(password);
        if (validation !== 'OK') {
            if (show)
                showAlert('info', validation);
            return false;
        }
        return true;
    }

    const showAlert = (type, message) => {
        const id = uuidv4();
        const newAlert = { id, message, type };
        setAlerts([...alerts, newAlert]);
    };

    const handleCreate = async () => {

        if (!validade())
            return;

        try {
            const userCredential = await createAccount(email, password)

            var user = {
                id: uuidv4(),
                firebaseId: userCredential.multiFactor.user.uid,
                name: name,
                email: email,
                role: role,
                registerDate: (new Date().getTime())
            }
            try {
                await saveUser(user)

                if (role === 'Advogado')
                    sendNotification(user.id, 'Preencha seu endereço e informações profissionais para podermos validar sua conta.');

                showAlert('success', EMAIL_CONFIRMACAO);
                handleClick('signon')
            }
            catch (err) {
                showAlert('error', err.message)
                deleteAccount();
            }
        }
        catch (error) {
            if (error.code === 'auth/email-already-in-use')
                showAlert('error', EMAIL_EM_USO);
            if (error.code === 'auth/invalid-email')
                showAlert('error', EMAIL_INVALIDO);
            if (error.code === 'auth/weak-password')
                showAlert('error', SENHA_FRACA);
            if (error.code === 'auth/network-request-failed')
                showAlert('error', ERRO_CRIAR);
        }
    }

    const handleGoogle = async () => {
        const userCredential = await signInWithGoogle()
        if (userCredential.additionalUserInfo.isNewUser) {
            var user = {
                id: uuidv4(),
                firebaseId: userCredential.user.multiFactor.user.uid,
                name: userCredential.user.displayName ? userCredential.user.displayName : '',
                email: userCredential.user.email,
                contact: userCredential.user.phoneNumber ? userCredential.user.phoneNumber : '',
                role: role,
                registerDate: (new Date().getTime())
            }
            try {
                await saveUser(user)

                if (role === 'Advogado')
                    sendNotification(user.id, 'Preencha seu endereço e informações profissionais para podermos validar sua conta.');

                navigate('/professionals')
            }
            catch (err) {
                showAlert('error', err.message)
                deleteAccount();
            }
        }
        else
            navigate('/professionals')
    }

    const handleFacebook = async () => {
        const userCredential = await signInWithFacebook()
        if (userCredential.additionalUserInfo.isNewUser) {
            var user = {
                id: uuidv4(),
                firebaseId: userCredential.user.multiFactor.user.uid,
                name: userCredential.user.displayName ? userCredential.user.displayName : '',
                email: userCredential.user.email,
                contact: userCredential.user.phoneNumber ? userCredential.user.phoneNumber : '',
                role: role,
                registerDate: (new Date().getTime())
            }
            try {
                await saveUser(user)

                if (role === 'Advogado')
                    sendNotification(user.id, 'Preencha seu endereço e informações profissionais para podermos validar sua conta.');

                navigate('/professionals')
            }
            catch (err) {
                showAlert('error', err.message)
                deleteAccount();
            }
        }
        else
            navigate('/professionals')
    }

    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <div className={$.block}>
                    <div className={$.title} id={'title'}>
                        {
                            role === 'Advogado' ? 'Cadastre-se agora e ganhe 30 dias grátis!' : 'Cadastre-se agora!'
                        }

                    </div>
                    <div className={$.new}>Já possui uma conta? <span className={$.newLink} onClick={() => handleClick('signon')}>Login</span></div>
                    <div className={$.field}>
                        <TextField title='Nome Completo' get={name} set={setName} autofocus autocomplete='new-password' validate={evalName} />
                    </div>
                    <div className={$.field}>
                        <TextField title='E-mail' get={email} set={setEmail} autocomplete='new-password' validate={evalEmail} />
                    </div>
                    <div className={$.field}>
                        <TextField title='Senha' type='password' get={password} set={setPassword} autocomplete='new-password' validate={evalPassword} />
                        <div className={$.hint}>
                            <div>A senha precisa conter: no mínimo 8 caracteres, incluindo letras, números e caracteres especiais (! @ # $ % ^ & * ( ) , . ? &quot; : { } | )</div>
                        </div>
                    </div>
                    <div className={$.agree}>Ao criar minha conta eu concordo com o <span className={$.term} onClick={() => setPopup('termo')}>Termo de Serviço</span> e <span className={$.term} onClick={() => setPopup('privacidade')}>Política de Privacidade.</span></div>
                    <div className={$.button}>
                        <Button text='Criar Conta' color='var(--texto1)' textColor='var(--background)' size='large' stretch onClick={handleCreate} />
                    </div>
                    <div className={$.or}>
                        <span className={$.line}>OU</span>
                    </div>
                    <div className={$.social}>
                        <Badge icon={faFacebook} color={'#078dee'} onClick={() => handleFacebook()} id={'facebook'} />
                        <Badge icon={faGoogle} color={'#df3e30'} onClick={() => handleGoogle()} id={'google'} />
                    </div>
                </div>
                {
                    popup &&
                    <Dialog closeButton={true} onBlur={() => setPopup()}>
                        {popup === 'termo' && <TermoServico />}
                        {popup === 'privacidade' && <Privacidade />}
                        {popup === 'seguranca' && <Seguranca />}
                    </Dialog>
                }
            </div>
        </div >)
}
