import { React } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from './Loading.module.css';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function Loading(params) {

    const { enabled = false, children } = params;

    return (
        <div className={$.container}>
            {enabled ?
                <div className={$.wrapper}>
                    <FontAwesomeIcon icon={faSpinner} className={$.spin} />
                </div>
                :
                children}
        </div>
    )
}
