import React from 'react';
import $ from './PreSignIn.module.css';
import Button from '../../Components/Button/Button';

export default function PreSignIn(params) {

    const { handleClick, handleRole } = params;

    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <div className={$.block}>
                    <div className={$.title}>O que está procurando?</div>
                    <div className={$.new}>Você está buscando <span className={$.bold}>ajuda com um advogado</span> ou deseja <span className={$.bold}>ganhar mais visibilidade online</span>?</div>
                    <div className={$.field}>
                        <Button id={'option_client'} text={'Buscando assessoria jurídica'} color={'var(--blue)'} onClick={() => handleRole('Cliente')}></Button>
                        <Button id={'option_professional'} text={'Profissional em busca de visibilidade'} color={'var(--green)'} onClick={() => handleRole('Advogado')}></Button>
                    </div>
                    <div className={$.or}>
                        <span className={$.line}>OU</span>
                    </div>
                    <div className={$.social}>
                        <span className={$.back} onClick={() => handleClick('signon')}>Voltar ao Login</span>
                    </div>
                </div>
            </div>
        </div>)
}
