import { React } from 'react';
import { useState } from 'react';
import $ from './Accordion.module.css';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import AccordionDetails from './AccordionDetails';
import AccordionSummary from './AccordionSummary';

export default function Accordion(p) {

    const { title, children } = p;
    const [expanded, setExpanded] = useState(false);

    const action = () => {
        setExpanded(!expanded);
    }

    return (
        <div className={`${$.container} ${expanded && $.expanded}`}>
            <AccordionSummary title={title} icon={expanded ? faChevronUp : faChevronDown} action={action} />
            <AccordionDetails expanded={expanded}>
                {children}
            </AccordionDetails>
        </div>
    )
}