import $ from './Number.module.css';
import { React } from 'react';

export default function Number(params) {

    const { title, value } = params;

    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <div className={$.title}>{title}</div>
                <div className={$.infos}>
                    <div className={$.value}>{value}</div>
                    <div className={$.graph}></div>
                </div>
            </div>
        </div>
    )
}

