import $ from './Legify.module.css'
import What1 from './../../Images/what_1.webp';
import What2 from './../../Images/what_2.webp';
import { React, useMemo } from 'react';

const jobs = [
    { name: 'Análise de requisitos e levantamento de necessidades', percent: '10%' },
    { name: 'Definição de arquitetura e tecnologias', percent: '5%' },
    { name: 'Design e prototipação de interface', percent: '10%' },
    { name: 'Desenvolvimento e garantia de qualidade', percent: '70%' },
    { name: 'Implantação e configuração do ambiente', percent: '5%' },
]

export default function Legify() {

    const imagemWhat1 = useMemo(() => What1, []);
    const imagemWhat2 = useMemo(() => What2, []);

    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <div className={$.block}>
                    <div className={$.blockBody}>
                        <div className={$.blockHeader}>
                            <div className={$.blockTitle}>
                                QUEM SOMOS
                            </div>
                        </div>
                        <div className={$.infos}>
                            <div className={$.blockContent}>
                                <div className={$.text}>
                                    <div className={$.blockName}>O que é o Legify?</div>
                                    O Legify é uma plataforma que oferece soluções tecnológicas para facilitar o acesso à justiça e tornar o universo jurídico mais acessível e democrático. Nossa plataforma permite que usuários encontrem informações e respostas para suas dúvidas jurídicas, além de possibilitar a contratação de serviços jurídicos de maneira simplificada e transparente. O Legify tem como objetivo democratizar o acesso ao conhecimento e aos serviços jurídicos, tornando a justiça mais acessível e eficiente para todos.
                                    <div className={$.bars}>
                                        {jobs.map(j =>
                                            <div className={$.bar} key={j.name}>
                                                <div className={$.barInfos}>
                                                    <div>{j.name}</div>
                                                    <div className={$.barValue}>{j.percent}</div>
                                                </div>
                                                <div className={$.barLine}>
                                                    <div className={$.barFill} style={{ width: j.percent }} />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={$.block}>
                    <div className={$.blockBody}>
                        <div className={$.image}>
                            <img alt='Sobre Nós 1' src={imagemWhat1} className={$.what1} width='292' height='390' />
                            <img alt='Sobre Nós 2' src={imagemWhat2} className={$.what2} width='268' height='268' />
                        </div>
                    </div>
                </div>
            </div>
        </div >)
}