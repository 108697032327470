import { React, useState } from 'react'
import Button from '../../Components/Button/Button';
import TextField from '../../Components/TextField/TextField';
import $ from './NotifyAll.module.css'
import { sendNotification } from '../../Firebase';
import { searchUsers } from '../../Services/Database/User';

export default function NotifyAll() {

    const [notification, setNotification] = useState();

    const sendNotificationUsers = () => {

        if (!notification)
            return;

        searchUsers().then((users) => {
            if (users)
                users.forEach((user) => {
                    sendNotification(user.id, notification);
                })
            setNotification();
        })
    }

    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <div className={$.block}>
                    <div className={$.blockBody}>
                        <div className={$.blockHeader}>
                            <div className={$.blockTitle}>
                                NOTIFICAR USUÁRIOS
                            </div>
                        </div>
                        <div className={$.infos}>
                            <TextField title={'Notificação'} type={'textarea'} get={notification} set={setNotification} />
                            <div className={$.actions}>
                                <Button text={'Enviar'} color={'var(--primary_main)'} onClick={() => sendNotificationUsers()} />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}