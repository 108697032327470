import React from 'react';
import $ from './Notification.module.css'
import { updateDataInFirebase } from '../../Firebase';
import Avatar from '../../Components/Avatar/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { useContext } from 'react';
import { UserContext } from '../../Context/UserContext';

export default function Notification(p) { 

    const { notifications, refresh } = p;

    //const [refresh, setRefresh] = useState(false);

    const { user } = useContext(UserContext);

    const calculateTimeSpan = (time) => {
        const ONE_SECOND = 1000; // número de milissegundos em um segundo
        const ONE_MINUTE = 60 * ONE_SECOND; // número de milissegundos em um minuto
        const ONE_HOUR = 60 * ONE_MINUTE; // número de milissegundos em uma hora
        const ONE_DAY = 24 * ONE_HOUR; // número de milissegundos em um dia
        const ONE_MONTH = 30 * ONE_DAY; // número de milissegundos em um mês (assumindo 30 dias)

        const now = new Date(); // data atual
        const diff = now - time; // diferença em milissegundos

        if (diff < ONE_SECOND) {
            return `agora`;
        } else if (diff < ONE_MINUTE) {
            const seconds = Math.floor(diff / ONE_SECOND);
            return `${seconds} segundo${seconds !== 1 ? 's' : ''}`;
        } else if (diff < ONE_HOUR) {
            const minutes = Math.floor(diff / ONE_MINUTE);
            return `${minutes} minuto${minutes !== 1 ? 's' : ''}`;
        } else if (diff < ONE_DAY) {
            const hours = Math.floor(diff / ONE_HOUR);
            return `${hours} hora${hours !== 1 ? 's' : ''}`;
        } else if (diff < ONE_MONTH) {
            const days = Math.floor(diff / ONE_DAY);
            return `${days} dia${days !== 1 ? 's' : ''}`;
        } else {
            const months = Math.floor(diff / ONE_MONTH);
            return `${months} mês${months !== 1 ? 'es' : ''}`;
        }
    };

    const proccessPicture = (picture) => {
        return process.env.REACT_APP_FILE_SERVER + picture;
    }

    const updateRead = (notification) => {
        var newNotification = notification;
        newNotification.read = true;
        updateDataInFirebase('notifications/' + user.id, notification.id, newNotification)
        refresh()
    }

    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <div className={$.header}>
                    <div className={$.title}>Notificações</div>
                </div>
                <div>
                    {notifications.length > 0 ? notifications.map((item) => (
                        <div key={item.date} className={$.notification} onClick={() => updateRead(item)}>
                            <div className={$.avatarText}>
                                <Avatar picture={proccessPicture(item.picture)} size={45} color={'transparent'} />
                                <div>
                                    <div>{item.message}</div>
                                    <div className={$.time}>há {calculateTimeSpan(item.date)}</div>
                                </div>
                            </div>
                            <div className={$.read}>
                                {
                                    !item.read &&
                                    <FontAwesomeIcon icon={faCircle} />
                                }
                            </div>
                        </div>

                    ))
                        :
                        <div className={$.notification}>
                            Sem notificações
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
