import React, { cloneElement, useState } from 'react';
import $ from './Tabs.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function Tab(p) {

    const { title, isActive, onClick, icon, visible = true } = p;

    return (
        visible &&
        <div className={`${$.tab} ${isActive ? $.active : ''}`} onClick={onClick}>
            {icon && <FontAwesomeIcon className={$.tabIcon} icon={icon} />}
            <span className={$.tabTitle}>
                {title}
            </span>
        </div>
    ); 
}

export function Tabs(p) {

    const { children } = p;

    const [active, setActive] = useState(0);

    const tabs = React.Children.map(children, (child, index) => {
        return cloneElement(child, {
            isActive: index === active,
            onClick: () => setActive(index)
        })
    })

    const contents = React.Children.map(children, (child, index) => {
        return (
            <TabContent key={index} isActive={index === active}>
                {child.props.children}
            </TabContent>
        )
    })

    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <div className={$.tabs}>{tabs}</div>
                <div className={$.content}>{contents}</div>
            </div>
        </div>)
}

function TabContent({ children, isActive }) {
    return isActive ? <div>{children}</div> : null;
}