import React, { useEffect, useState } from "react";
import {
    PaymentElement,
    LinkAuthenticationElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import Button from "../../Components/Button/Button";
import $ from './StripeForm.module.css';
import { useContext } from "react";
import { UserContext } from "../../Context/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function CheckoutForm(params) {

    const { clientSecret, intent = 'setup', onSuccess } = params;

    const { user } = useContext(UserContext);

    const stripe = useStripe();
    const elements = useElements();
    const [message] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        if (!stripe)
            return;

        if (intent === 'setup')
            stripe.retrieveSetupIntent(clientSecret);
        else
            stripe.retrievePaymentIntent(clientSecret);

    }, [stripe]);

    const pay = async () => {

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        var result = null;

        if (intent === 'setup')
            result = await stripe.confirmSetup({
                elements,
                redirect: "if_required"
            });
        else
            result = await stripe.confirmPayment({
                elements,
                redirect: "if_required"
            });

        if (!result.error) {
            onSuccess();
        }
        else
            alert(result.error.message)
        // if (result.error.type === "card_error" || result.error.type === "validation_error")
        //     setMessage(result.error.message);

        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs",
    }

    const authOptions = {
        defaultValues: {
            email: user.email
        }
    }

    return (
        <div id="payment-form" className={$.container}>
            <div style={{ display: "none" }}>
                <LinkAuthenticationElement id="link-authentication-element" options={authOptions} />
            </div>
            <PaymentElement id="payment-element" className={$.stripeElement} options={paymentElementOptions} />
            <div className={$.padding}>
                {isLoading ? <FontAwesomeIcon icon={faSpinner} className={$.loading} /> : <Button text='Tornar-se Membro' color='var(--primary_main)' stretch onClick={pay} />}
            </div>
            {message && <div id="payment-message" className={$.message}>{message}</div>}
        </div>
    );
}