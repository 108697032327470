import $ from './ProfileSettings.module.css'
import Header from '../_components/Header';
import { Tabs, Tab } from '../../Components/Tabs/Tabs';
import { faUserCircle, faCreditCard, faShareNodes, faKey, faScaleBalanced, faCog } from '@fortawesome/free-solid-svg-icons';
import General from './General';
import Billing from './Billing';
import SocialLinks from './SocialLinks';
import ChangePassword from './ChangePassword';
import { React, useContext } from 'react';
import { UserContext } from '../../Context/UserContext';
import Professional from './Professional';
import { getWorldLocation } from '../../Services/Maps';
import { v4 as uuidv4 } from 'uuid';
import { AlertContext } from '../../Context/AlertContext';
import Appearance from './Appearance';
import { canAccess } from '../../Utils/Professional';
import { saveUser } from '../../Services/Database/User';

export default function ProfileSettings() {

    const { user, setUser } = useContext(UserContext);
    const { alerts, setAlerts } = useContext(AlertContext);

    const handleChanges = async (newUser, showMessage = true) => {
        if (showMessage)
            showAlert('success', 'Suas informações de cadastros foram atualizadas');
        const location = await getWorldLocation(newUser.address, newUser.city);
        if (location) {
            newUser.lat = location.lat;
            newUser.lon = location.lon;
        }
        setUser({ ...user, ...newUser });
        saveUser(newUser);
        sessionStorage.clear();
    }

    const showAlert = (type, message) => {
        const id = uuidv4();
        const newAlert = { id, message, type };
        setAlerts([...alerts, newAlert]);
    };

    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <Header
                    title={'Perfil'}
                    alert={user?.role === 'Advogado' && !user?.validated ? true : false}
                    showCheck
                />
                <Tabs>
                    <Tab icon={faUserCircle} title={'Geral'}>
                        <General handleChanges={handleChanges} />
                    </Tab>
                    <Tab icon={faScaleBalanced} title={'Profissional'} visible={canAccess(user?.role)}>
                        <Professional handleChanges={handleChanges} />
                    </Tab>
                    <Tab icon={faCreditCard} title={'Pagamento'} visible={user?.role === 'Advogado'}>
                        <Billing handleChanges={handleChanges} />
                    </Tab>
                    <Tab icon={faShareNodes} title={'Redes Sociais'}>
                        <SocialLinks handleChanges={handleChanges} />
                    </Tab>
                    <Tab icon={faKey} title={'Alterar Senha'}>
                        <ChangePassword handleChanges={handleChanges} />
                    </Tab>
                    <Tab icon={faCog} title={'Aparência'}>
                        <Appearance handleChanges={handleChanges} />
                    </Tab>
                </Tabs>
            </div>
        </div>)
}