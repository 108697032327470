import $ from './TopMenu.module.css'
import Avatar from '../Components/Avatar/Avatar';
import Badge from '../Components/Badge/Badge';
import Popup from '../Components/Popup/Popup';
import { useContext, useState } from 'react';
import AvatarMenu from './_components/AvatarMenu';
import { UserContext } from '../Context/UserContext';
import firebase from 'firebase/compat/app';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import Notification from './_components/Notification';
import { React, useEffect } from 'react';

export default function TopMenu() {

    const { user } = useContext(UserContext);
    const [refresh, setRefresh] = useState(false);
    const [avatar, setAvatar] = useState(false);
    const [notification, setNotification] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [notificationCount, setNotificationCount] = useState(0);
    const notificationsRef = firebase.database().ref(`notifications/${user.id}`);

    const showAvatar = () => {
        setAvatar(!avatar);
    }

    const showNotification = () => {
        setNotification(!notification);
    }

    useEffect(() => {
        notificationsRef.on('value', (snapshot) => {
            var list = []
            snapshot.forEach((childSnapshot) => {
                var childData = childSnapshot.val();
                if (childSnapshot.key)
                    childData.id = childSnapshot.key;
                list.push(childData);
            });
            list = list.sort((a, b) => { return b.date - a.date });
            setNotificationCount(list.filter(x => x.read === false).length);
            setNotifications(list);
        });
    }, [refresh])

    return (
        <div className={$.container}>
            <div className={$.wrapper}>
                <Badge icon={faBell} value={notificationCount} onClick={showNotification}>
                    {
                        notification &&
                        <Popup onBlur={showNotification}>
                            <Notification notifications={notifications} refresh={() => setRefresh(!refresh)} />
                        </Popup>
                    }
                </Badge>
                <Avatar name={user?.name} picture={process.env.REACT_APP_FILE_SERVER + user?.picture} onClick={showAvatar}>
                    {
                        avatar &&
                        <Popup onBlur={showAvatar}>
                            <AvatarMenu name={user?.name} email={user?.email} />
                        </Popup>
                    }
                </Avatar>

            </div>
        </div>
    )

}