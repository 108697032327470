const server = process.env.REACT_APP_SERVER;

export const saveUser = async (user) => {
    delete user.subscription;
    delete user.areas;
    const response = await fetch(server + 'user', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    });

    if (!response.ok)
        throw new Error('Erro ao cadastrar o usuário');
};

export const searchUsers = async (filters) => {
    try {
        const response = await fetch(server + 'user/search', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(filters)
        });

        if (response.ok) {
            return await response.json();
        } else {
            throw new Error('Erro ao buscar usuários');
        }
    } catch (error) {
        throw new Error(error);
    }
};

export const saveUserArea = async (area) => {
    const response = await fetch(server + 'user/area', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(area)
    });

    if (!response.ok)
        throw new Error('Erro ao cadastrar a área do usuário');
};

export const removeUserArea = async (area) => {
    const response = await fetch(server + 'user/area', {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(area)
    });

    if (!response.ok)
        throw new Error('Erro ao excluir a área do usuário');
};